import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";
import _ from "lodash";

import useIsMobile from "../hooks/useIsMobile";

import { IconClose, IconLeft, IconRight } from "../../../../../icons";
import Modal from "../../../../Modal";
import Button from "../../../../Button";
import Loader from "../../../../Loader";
import useDimensions from "../../../../Common/Hooks/useDimensions";
import { AnimatePresence, motion } from "framer-motion";

import { alphabet } from "../../../../TestEditor/constants";
import { interfaceText, locale } from "../../../../../helpers";

interface PreferenceSelectProps {
  type: "single" | "pairwise";
  data: { id: string, replyValue: string, image: string }[];
  isRandomized: boolean;
  onOptionSubmitted: (selectedOption: { id: string, replyValue: string }) => void;
  onOptionSelected: (selectedOption: { id: string, replyValue: string }) => void;
}

// Main component
export default function PreferenceSelect(props: PreferenceSelectProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { data, isRandomized } = props;
  const [options, setOptions] = useState<{ id: string, replyValue: string, image: string, density?: "1x" | "2x" }[] | null>(null);
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const [showModalModeUi, setShowModalModeUi] = useState<Boolean>(true);
  const isMobile = useIsMobile();
  const [imageDimensions, setImageDimensions] = useState<{ [key: string]: { maxWidth: number, maxHeight: number } }>({});
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setOptions(isRandomized ? _.shuffle(data) : data);
    setSelectedOptionId(null);
  }, [data]);

  useEffect(() => {
    if (!selectedOptionId) return;
    const replyValue = options?.find((option) => option.id === selectedOptionId)?.replyValue;
    props.onOptionSelected({ id: selectedOptionId, replyValue: replyValue! });
    // updateAnswerBlock("selectedOptions", [{ id: selectedOptionId, replyValue }]);
  }, [selectedOptionId]);

  function onOptionSelected(id: string) {
    setSelectedOptionId(id);
    setIsModalOpen(true);
    setShowModalModeUi(true);
  }

  useEffect(() => {
    if (isMobile && isModalOpen) {
      setTimeout(() => {
        setShowModalModeUi(false);
      }, 3500);
    }
  }, [isMobile, isModalOpen]);

  useEffect(() => {
    if (!data) return;

    const loadImage = (item: { id: string, image: string, density?: "1x" | "2x" }) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = item.image;
        img.onload = () => {
          const density = item.density ?? "1x"; // Default to "1x" if undefined
          const maxWidth = density === "2x" ? img.naturalWidth / 2 : img.naturalWidth;
          const maxHeight = density === "2x" ? img.naturalHeight / 2 : img.naturalHeight;
          setImageDimensions((prev) => ({
            ...prev,
            [item.id]: { maxWidth, maxHeight }
          }));
          resolve();
        };
      });
    };

    Promise.all(data.map(loadImage)).then(() => {
      setAllImagesLoaded(true);
    });
  }, [data]);

  const selectPreviousOption = useCallback(() => {
    if (!options || !selectedOptionId) return;
    const currentIndex = options?.findIndex((option) => option.id === selectedOptionId);
    const previousIndex = currentIndex === 0 ? options?.length - 1 : currentIndex - 1;
    setSelectedOptionId(options?.[previousIndex]?.id);
  }, [options, selectedOptionId]);

  const selectNextOption = useCallback(() => {
    if (!options || !selectedOptionId) return;
    const currentIndex = options?.findIndex((option) => option.id === selectedOptionId);
    const nextIndex = currentIndex === options?.length - 1 ? 0 : currentIndex + 1;
    setSelectedOptionId(options?.[nextIndex]?.id);
  }, [options, selectedOptionId]);

  const modalImageContainerRef = useRef<HTMLDivElement | null>(null);
  const modalImageRef = useRef<HTMLImageElement | null>(null);

  const { width: containerWidth, height: containerHeight } = useDimensions({ ref: modalImageContainerRef, imageLoaded });
  const { width: imageWidth, height: imageHeight } = useDimensions({ ref: modalImageRef, imageLoaded });

  const currentOptionIndex = options?.findIndex((option) => option.id === selectedOptionId);
  const currentOptionNumber = currentOptionIndex !== undefined ? currentOptionIndex + 1 : undefined;
  const totalOptions = options?.length;

  const gridXlColumnsClass = options && options.length > 2 ? "xl:grid-cols-3" : "xl:grid-cols-2";

  function submitOption() {
    setIsModalOpen(false);
    if (!!options && !!currentOptionNumber) {
      props.onOptionSubmitted({ id: options?.[currentOptionNumber - 1]?.id, replyValue: options?.[currentOptionNumber - 1]?.replyValue });
    }
  }

  if (!allImagesLoaded) {
    return <Loader />;
  }

  return (
    <>
      <div className={clsx("preference-select__container grid grid-cols-1 md:grid-cols-2 gap-4 mt-2", gridXlColumnsClass)}>
        {options?.map((item, i) => (
          <div key={item.id}>
            <PreferenceOption
              key={item.id}
              id={item.id}
              replyValue={item.replyValue}
              image={item.image}
              density={item.density}
              onClick={onOptionSelected}
              optionIndex={i}
            />
          </div>
        ))}
      </div>
      <Modal
        width="100%"
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        fullScreen={true}
        noRoundedCorners={true}
        background={false}
        backdrop
      >
        <div className="preference-select__modal-image-container flex flex-col h-full relative">
          <AnimatePresence>
            {showModalModeUi && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25, ease: "easeIn" }}
                className="modal-image-controls"
              >
                {currentOptionIndex !== undefined && totalOptions !== undefined && (
                  <>
                    {currentOptionIndex > 0 && (
                      <ArrowButton direction="left" onClick={selectPreviousOption} />
                    )}
                    {currentOptionIndex < totalOptions - 1 && (
                      <ArrowButton direction="right" onClick={selectNextOption} />
                    )}
                  </>
                )}
                <CloseButton onClick={() => setIsModalOpen(false)} />
              </motion.div>
            )}
          </AnimatePresence>
          <div
            ref={modalImageContainerRef}
            className="flex-grow-default h-full max-h-full overflow-y-auto flex items-start justify-center"
            onClick={() => setShowModalModeUi(!showModalModeUi)}
          >
            <img
              ref={modalImageRef}
              src={options?.find((option) => option.id === selectedOptionId)?.image}
              alt=""
              onLoad={() => {
                setImageLoaded(true);
              }}
              style={{
                width: "100%",
                maxWidth: selectedOptionId ? imageDimensions[selectedOptionId]?.maxWidth : undefined,
                maxHeight: selectedOptionId ? imageDimensions[selectedOptionId]?.maxHeight : undefined,
              }}
              className={clsx("object-contain", {
                "self-start": imageHeight > containerHeight,
                "self-center": imageHeight <= containerHeight,
              })}
            />
          </div>
          <BottomBar
            currentOptionNumber={currentOptionNumber}
            totalOptions={totalOptions}
            caption={options?.find((option) => option.id === selectedOptionId)?.replyValue}
            onSelect={submitOption}
          />
        </div>
      </Modal>
    </>
  );
}

function Bullet({ optionIndex, className }: { optionIndex: number, className?: string }) {
  return <div className={clsx("w-6 h-6 rounded-md bg-gray-300 flex items-center justify-center text-gray-700 font-bold text-sm capitalize", className)}>{alphabet[optionIndex]}</div>;
}

// Define animation variants
const preferenceOptionVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

// Define interfaces for props
interface PreferenceOptionProps {
  id: string;
  replyValue: string;
  image: string;
  onClick: (id: string, replyValue: string) => void;
  optionIndex: number;
  density?: "1x" | "2x";
}

// Utility components
function PreferenceOption(props: PreferenceOptionProps) {
  const withCaption = props.replyValue && props.replyValue.length > 0;
  const preferenceOptionContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <motion.div
      ref={preferenceOptionContainerRef}
      id={"preferenceOptionContainer_" + props.id}
      className={clsx("flex items-center justify-center")}
      key={props.id}
      variants={preferenceOptionVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <div
        className={clsx(
          "p-3 rounded-md border-1 transition-all duration-75 ease-in h-auto relative cursor-pointer border-gray-300 hover:border-transparent hover:ring-2 hover:ring-blue-500",
        )}
        style={{
          maxWidth: props.density === "2x" ? "50%" : "100%",
          maxHeight: props.density === "2x" ? "50%" : "100%",
        }}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(props.id, props.replyValue);
        }}
      >
        <Bullet className="absolute top-2 left-2" optionIndex={props.optionIndex} />
        <img
          src={props.image}
          alt=""
          className={clsx("h-auto object-contain")}
        />
        {withCaption && <div className={clsx("flex items-start mt-2 text-gray-800 font-medium")}>{props.replyValue}</div>}
      </div>
    </motion.div>
  );
}

interface ArrowButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
}

function ArrowButton({ direction, onClick }: ArrowButtonProps) {
  const isMobile = useIsMobile();
  const buttonSize = isMobile ? "size-10" : "size-12";
  const buttonShadow = "shadow-xl";

  return (
    <div
      className={clsx(
        "arrow-button flex absolute bg-white items-center justify-center border-gray-300 border-1 rounded-lg cursor-pointer hover:opacity-75 transition-all duration-75 ease-in z-10 top-[50%] -translate-y-1/2",
        `arrow-button--${direction}`,
        direction === "left" ? (isMobile ? "left-4" : "left-6") : (isMobile ? "right-4" : "right-6"),
        buttonSize,
        buttonShadow
      )}
      onClick={onClick}
    >
      {direction === 'left' ? <IconLeft className={clsx("w-6 h-6")} /> : <IconRight className={clsx("w-6 h-6")} />}
    </div>
  );
}

function CloseButton({ onClick }: { onClick: () => void }) {
  const isMobile = useIsMobile();
  const buttonSize = isMobile ? "size-10" : "size-12";
  const buttonShadow = "shadow-lg";

  return (
    <div
      className={clsx("close-button absolute cursor-pointer rounded-lg bg-white p-2 border-gray-300 border-1 z-10 flex items-center justify-center hover:opacity-75 transition-all duration-75 ease-in", buttonSize, buttonShadow, isMobile ? "top-4 right-4" : "top-6 right-6")}
      onClick={onClick}
    >
      <IconClose className="w-6 h-6 fill-current text-gray-800" />
    </div>
  );
}

function BottomBar({ currentOptionNumber, totalOptions, caption, onSelect }: { currentOptionNumber: number | undefined; totalOptions: number | undefined; caption: string | undefined; onSelect: () => void }) {

  const isMobile = useIsMobile();


  if (!currentOptionNumber || !totalOptions) return null;
  return (
    <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 50 }} transition={{ duration: 0.15, ease: "easeIn" }} className="preference-select__modal-bottombar flex-shrink-0 h-16 bg-white w-full flex items-center justify-between p-3 shadow-lg border-t-1 border-gray-300">
      {caption ? (
        <>
          <div className={clsx("bottombar-caption text-lg font-medium flex items-center gap-2", isMobile ? "text-sm" : "text-lg")}>
            <Bullet optionIndex={currentOptionNumber - 1} />
            <span>{caption}</span>
          </div>
        </>
      ) : (
        <div className={clsx("bottombar-caption text-gray-800 text-lg font-medium")}>
          <Bullet optionIndex={currentOptionNumber - 1} />
        </div>
      )}
      <Button className={clsx("")} name={interfaceText.chooseThisOption[locale()]} type="primary" handler={onSelect} large={!isMobile} />
    </motion.div>
  );
}