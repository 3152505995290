import React, { useEffect, useState } from "react";
import _ from 'lodash';

import { FigmaHtmlClickmap, FigmaClicks, FigmaHeatmap } from "../Clickmaps";
import Loader from "../../Loader";
import { IReportPrototypeClick, IReportScreenState } from "./FigmaReport";
import FigmaHtmlPrototypeReportView, { IFigmaReportViewData } from "../../Figma/FigmaHtmlPrototypeReportView";
import { FigmaReportSidebar } from './FigmaReportSidebar';

import useReportScreenTabs, { tabsLabels } from '../hooks/useReportScreenTabs';
import { useFigmaHtmlClickAreaStats } from '../Clickareas/hooks/useFigmaHtmlClickAreaStats';

import { IClickmap } from "../Clickmaps/STORAGE";

import { IFormattedPrototype } from "../../../utils/figma";

import { IArea } from '../models';
import { IFigmaResponse } from "../../../models/Response";

const rightColumnStyle = {
	minWidth: 230,
	maxWidth: 230,
};

interface IHtmlPrototypeScreenReportProps {
	loading: boolean;
	screen: IReportScreenState;
	prototype: IFormattedPrototype;
	onLoad: (params: any) => void;
	clickmap: IClickmap;
	onAreaAdded: (areas: IArea[]) => void;
	// figmaImage: IFigmaImage;
	isByScreenMode: boolean;
	activeTab: string;
	responses: IFigmaResponse[];
}

export function HtmlPrototypeScreenReport(props: IHtmlPrototypeScreenReportProps) {
	const { screen, isByScreenMode, activeTab } = props;

	const loading = props.loading;

	const [clicksWithCorrection, setClicksWithCorrection] = useState<IReportPrototypeClick[]>([...screen.clicks] || []);
	const [prototypeResizeParams, setPrototypeResizeParams] = useState<any | undefined>(undefined);
	const canShowClicksOrder = !clicksWithCorrection.some((click) => !click.timestamp);
	const canSetShowOnlyFirstClicks = !clicksWithCorrection.some((click) => !click.number);

	const [showClicksOrder, setShowClicksOrder] = useState(true);

	const [nodePositions, setNodePositions] = useState<IFigmaReportViewData["nodePositions"] | null>(null);

	const clickAreaStats = useFigmaHtmlClickAreaStats(clicksWithCorrection, props.clickmap.areas);

	function setClicksCorrection() {
		if (!nodePositions) return;
		const newClicks: IReportPrototypeClick[] = getCorrectedClicks(screen.clicks);

		setClicksWithCorrection(newClicks);
	}

	function getCorrectedClicks(clicks: IReportPrototypeClick[]) {
		const newClicks: IReportPrototypeClick[] = [];
		const nodsP: any = [];
		clicks.forEach((click) => {
			const nodeP = nodePositions?.[click.clickData.nodeId];
			nodsP.push(nodeP);
			const clickCopy = _.cloneDeep(click);
			clickCopy.clickData.left += nodeP?.left || 0;
			clickCopy.clickData.top += nodeP?.top || 0;
			newClicks.push(clickCopy);
		});

		if (canShowClicksOrder) {
			newClicks.sort((a, b) => a.timestamp - b.timestamp);
		}
		// console.log("getCorrectedClicks", newClicks, nodsP);
		return newClicks;
	}

	function updateResizeParams(newParams: any) {
		if (!_.isEqual(prototypeResizeParams, newParams)) {
			setPrototypeResizeParams(newParams);
		}
	}

	const figmaReportPrototype = (
		<FigmaHtmlPrototypeReportView
			screen={props.screen}
			prototype={props.prototype}
			onLoad={(data) =>{
				props.onLoad(data);
				setNodePositions(data.nodePositions);
				setClicksCorrection();
			}}
			onResize={updateResizeParams}
		/>
	);

	return (
		<>
			{loading && (
				<div className="figma-modal-body__loader flex-auto rounded-2xl bg-gray-800 p-8 absolute top-0 left-0 w-full h-full z-50 overflow-auto">
					<Loader />
					<div className="invisible inset-0 w-px h-px overflow-auto absolute">
						{figmaReportPrototype}
					</div>
				</div>
			)}
			{!loading && isByScreenMode && (
				<div className="figma-modal-body figma-modal-body_mode_byscreen flex-auto rounded-2xl w-5/6 bg-gray-900 opacity-90 h-full overflow-auto">
					<FigmaHtmlClickmap
						clickmap={props.clickmap}
						onAreaAdded={props.onAreaAdded}
						figmaPrototype={figmaReportPrototype}
						hideAreas={false}
						testersCount={clickAreaStats.testersCount}
						clickAreas={clickAreaStats.areasWithWithTargetClicks}
					>
						{(withScale, getScaleRate) => (
							<>
								<div className={activeTab === tabsLabels.CLICKS ? "block" : "hidden"}>
									<FigmaClicks
										width={props.clickmap.width}
										height={props.clickmap.height}
										clicks={clickAreaStats.filteredClicks}
										withScale={withScale}
										showClicksOrder={false}
										key={`clicks-${getScaleRate(props.clickmap.width)}`}
									/>
								</div>
								<div className={activeTab === tabsLabels.HEATMAP ? "block" : "hidden"}>
									<FigmaHeatmap
										width={props.clickmap.width}
										height={props.clickmap.height}
										clicks={clickAreaStats.filteredClicks}
									/>
								</div>
							</>
						)}
					</FigmaHtmlClickmap>
				</div>
			)}

			{!loading && !isByScreenMode && (
				<div className="figma-modal-body figma-modal-body_mode_regular flex-auto rounded-2xl w-5/6 bg-gray-900 opacity-90 h-full overflow-auto">
					<FigmaHtmlClickmap
						clickmap={props.clickmap}
						onAreaAdded={props.onAreaAdded}
						hideAreas={true}
						figmaPrototype={figmaReportPrototype}
						testersCount={clickAreaStats.testersCount}
						clickAreas={clickAreaStats.areasWithWithTargetClicks}
					>
						{(withScale, getScaleRate) => (
							<FigmaClicks
								width={props.clickmap.width}
								height={props.clickmap.height}
								clicks={clicksWithCorrection}
								withScale={withScale}
								showClicksOrder={showClicksOrder}
								key={`clicks-${getScaleRate(props.clickmap.width)}`}
							/>
						)}
					</FigmaHtmlClickmap>
				</div>
			)}

			<div className="flex-none ml-6 h-full overflow-auto" style={rightColumnStyle}>
				<FigmaReportSidebar
					showFirstClickControls={!loading && isByScreenMode && canSetShowOnlyFirstClicks}
					showClicksOrderControls={!loading && !isByScreenMode && canShowClicksOrder}
					showClickAreas={!loading && isByScreenMode}
					screenStats={screen.stats}
					onAreaAdded={props.onAreaAdded}
					setShowOnlyFirstClicks={clickAreaStats.setShowOnlyFirstClicks}
					showOnlyFirstClicks={clickAreaStats.showOnlyFirstClicks}
					setShowClicksOrder={setShowClicksOrder}
					showClicksOrder={showClicksOrder}
					clickAreaStats={clickAreaStats}
				/>
			</div>
		</>
	);
}


