import { useEffect, useState } from 'react';
import _ from 'lodash';

import { BaseBlock, FigmaBlock, IFigmaBlockWithPrototypeData } from '../../../../../../../models/Test';
import { IPrototypeClickEvent } from '../../../../../../../models/Figma/IClickEvent';
import { IClick } from '../../../../../../../models/Response';

import { Action, OverlayAction, isCloseOverlayAction, isNodeAction, isValidOverlayAction } from '../../../../../../../utils/figma';

import useRecording from '../../BlockSettings/hooks/useRecording';
import { usePermissionsContext } from '../../BlockSettings/context/PermissionsContext';
import { RecordResult } from '../../../../../../Common/RecordResult';

export type FigmaBlockData = IFigmaBlockWithPrototypeData & FigmaBlock & BaseBlock;

export interface IUseFigamHandlersOptions {
  withVideo: boolean;
  testId: string;
  blockId: string;
  answerId: string;
  data: FigmaBlockData;

  onGoalNodeReached: () => void;
  handleFigmaGiveUp: (data: FigmaPrototypeData) => void;
}

export default function useFigmaHandlers(options: IUseFigamHandlersOptions) {
  const { withVideo: useVideo } = options;
  const [figmaPrototypeState, setFigmaPrototypeState] = useState(getPrototypeInitialState(options.data));
  const { screenStreamRef, blockPermissionSettings } = usePermissionsContext();
  const [isSucceed, setIsSucceed] = useState<Boolean | null>(null);
  const [isGivenUp, setIsGivenUp] = useState<Boolean | null>(null);
  const recording = useRecording({
    permissions: blockPermissionSettings,
    screenStreamRef: screenStreamRef!,
    options: { blockId: options.blockId, answerId: options.answerId },
    prototypeLinkExists: !!options.data.prototypeLink,
    blockType: options.data.type,
    isBlockWithVideo: !!options.data.withVideo,
  });


  useEffect(() => {
    // Reset state variables when blockId is updated
    setFigmaPrototypeState(getPrototypeInitialState(options.data));
    setIsSucceed(null);
    setIsGivenUp(null);
  }, [options.data, options.blockId, options.testId, options.answerId]);


  useEffect(() => {
    if (!figmaPrototypeState) return;
    const goalNodeReached = Array.isArray(options.data.goalNode)
      ? (options.data.goalNode as string[]).includes(_.last(figmaPrototypeState.path) as string)
      : options.data.goalNode === _.last(figmaPrototypeState.path);

    if (!goalNodeReached) return;
    onTaskSuccess();
  }, [figmaPrototypeState?.path]);

  useEffect(() => {
    if (isGivenUp || isSucceed) {
      if (isGivenUp) {
        options.handleFigmaGiveUp(figmaPrototypeState as FigmaPrototypeData);
      }
      if (isSucceed) {
        options.onGoalNodeReached();
      }
    }
  }, [isSucceed, isGivenUp]);

  //#region Figma data update functions

  function onTaskStart() {
    const figmaResponseUpdate: any = {
      currentNodeResponseStart: _.now(),
      responseStart: _.now(),
      withVideo: useVideo,
      devicePixelRatio: window.devicePixelRatio,
    };

    // sets initial prototype state
    updateFigmaData(() => figmaResponseUpdate);

    if (useVideo) {
      recording.start();
    }
  }

  async function onTaskSuccess() {

    updateFigmaData(current => {
      return {
        nodeEventData: {
          [current.currentNode]: {
            timeSpent: 0
          }
        }
      };
    });

    if (useVideo) {
      // stopping events and canvas recording after timeout иначе иногда видео обрывается раньше чем должно
      setTimeout(async () => {
        const recordResult = await recording.finish();
        updateFigmaData(current => ({ recordResult }));
      }, 1000);
    }
    setIsSucceed(true);
  }

  async function onTaskGiveUp() {

    const now = _.now();

    updateFigmaData(current => {
      return {
        nodeEventData: {
          [current.currentNode]: {
            timeSpent: now - (current.currentNodeResponseStart || 0),
          }
        }
      };
    });

    if (useVideo) {
      const recordResult = await recording.finish();
      updateFigmaData(current => ({ recordResult }));
    }

    setIsGivenUp(true);

  }

  function onFigmaClick(data: IPrototypeClickEvent) {
    if (isValidOverlayAction(data?.action)) {
      addClickToFigmaData(data);
      if (data.action?.destinationId) {
        addTransitionToFigmaData(data as Required<IPrototypeClickEvent>);
      }
    } else {
      addClickToFigmaData(data);
      if (isNodeAction(data?.action) && data.action?.destinationId) {
        addTransitionToFigmaData(data as Required<IPrototypeClickEvent>);
      }
      else if (isCloseOverlayAction(data?.action)) {
        addTransitionToFigmaData(data as Required<IPrototypeClickEvent>);
      }
    }

  }

  function onFigmaSizeRetrieved(data: any) {
    const sizeUpdate = { size: data };
    updateFigmaData(current => sizeUpdate);
  }

  //#endregion

  function addClickToFigmaData(clickEvent: IPrototypeClickEvent) {
    // adds click to prototype state
    updateFigmaData(current => addClickReducer(current, clickEvent));
  }

  function addTransitionToFigmaData(transitionEvent: Required<IPrototypeClickEvent>) {
    let isOverlayAction = false;
    let destinationNodeId = '';

    if (isValidOverlayAction(transitionEvent.action)) {
      // transitionNodeID = figmaPrototypeState?.path[figmaPrototypeState?.path.length - 1] as string;
      isOverlayAction = true;
      destinationNodeId = _.last(figmaPrototypeState?.path) as string;
    }
    else if (isCloseOverlayAction(transitionEvent.action)) {
      isOverlayAction = true;
      destinationNodeId = _.last(figmaPrototypeState?.path) as string;
    } else {
      destinationNodeId = transitionEvent.action?.destinationId as string;
    }

    if (useVideo) {
      recording.recordEvent("transition", { transitionNodeID: destinationNodeId });
    }
    updateFigmaData(current => addTransitionReducer(current, destinationNodeId, isOverlayAction ? transitionEvent.action as OverlayAction : undefined));
  }

  // handles transition in figma prototype in Iframe
  function onFigmaTransition(transitionEvent: { presentedNodeId: string }) {
    let destinationNodeId = transitionEvent.presentedNodeId;

    updateFigmaData(current => {
      // get last event from current nodeEventData
      const currentNodeEventData = current.nodeEventData[current.currentNode];
      const lastClick = _.last(currentNodeEventData?.clicks);
      if (lastClick) {
        lastClick.handled = true;
      }
      return addTransitionReducer(current, destinationNodeId);
    });
  }

  function updateFigmaData(reducer: figmaDataReducer) {

    function arrayMergeFunction(objValue: any, srcValue: any[]) {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    }

    setFigmaPrototypeState((currentState) => {
      if (!currentState) return currentState;

      const update = reducer(currentState);
      const mergedState = _.mergeWith({}, currentState, update, arrayMergeFunction);

      const nextState: FigmaPrototypeData = { ...currentState };

      for (const key in mergedState) {
        if (mergedState.hasOwnProperty(key)) {
          const typedKey = key as keyof FigmaPrototypeData;
          if (!_.isEqual(mergedState[typedKey], currentState[typedKey])) {
            // Unchanged values are not updated to keep references
            (nextState as any)[typedKey] = mergedState[typedKey];
          }
        }
      }

      return nextState;
    });

  }

  function addClickReducer(currentFD: FigmaPrototypeData, clickEvent: IPrototypeClickEvent) {
    const clickData = clickEvent.clickData;
    const destinationId = clickEvent.action?.destinationId;

    if (!currentFD) return currentFD;
    return {
      nodeEventData: {
        [currentFD.currentNode]: {
          clicks: [
            {
              handled: Boolean(destinationId),
              timestamp: _.now(),
              action: clickEvent.action,
              ...clickData
            },
          ],
        },
      },
    } as any;
  }

  function addTransitionReducer(currentFD: FigmaPrototypeData, destinationNodeId: string, overlayAction?: Action) {
    if (!currentFD) return currentFD;

    const now = _.now();

    if (currentFD.path.length === 0) {
      // If path is empty, it's the first transition in the prototype and this can happen only in native prototypes
      const defaultFigmaScreenEventData = getDefaultFigmaScreenEventData();
      defaultFigmaScreenEventData.nodeId = destinationNodeId;
      return {
        currentNode: 0,
        currentNodeResponseStart: now,
        path: [destinationNodeId],
        nodeEventData: {
          0: defaultFigmaScreenEventData,
        },
      } as any
    }

    const currentScreenTimeSpent = now - (currentFD.currentNodeResponseStart as number);
    const currentNodeIndex = currentFD.currentNode;
    const currentNodeOverlays = currentFD.nodeEventData[currentNodeIndex].overlays;
    const nextNodeIndex = currentFD.path.length;
    const nextScreenData = getDefaultFigmaScreenEventData();
    nextScreenData.nodeId = destinationNodeId;
    if (isValidOverlayAction(overlayAction)) {
      nextScreenData.overlays = [...currentNodeOverlays, overlayAction] as any;
    } else if (isCloseOverlayAction(overlayAction)) {
      // remove last overlay
      nextScreenData.overlays = currentNodeOverlays.slice(0, -1) as any;
    }
    else {
      nextScreenData.overlays = [];
    }

    return {
      currentNode: nextNodeIndex,
      currentNodeResponseStart: _.now(),
      path: [destinationNodeId],
      nodeEventData: {
        [currentNodeIndex]: {
          timeSpent: currentScreenTimeSpent
        },
        [nextNodeIndex]: nextScreenData,
      },
    } as any
  }

  return {
    recording,
    prototypeState: figmaPrototypeState,
    onTaskStart,
    onTaskSuccess,
    onTaskGiveUp,
    onFigmaClick,
    onFigmaTransition,
    onFigmaSizeRetrieved
  }
}

function getPrototypeInitialState(data: FigmaBlockData) {
  if (!data) return null;

  // Initial state assignment is different for native prototypes and prototypes imported from plugin.
  // If prototypeLink is present, it's a native prototype and we don't need to add the first node to the path, because we'll get it from the prototype itself via emitted event.

  const withPrototypeLink = !!data.prototypeLink;

  const initialData = getDefaultFigmaPrototypeData(withPrototypeLink);

  if (!withPrototypeLink) {
    initialData.nodeEventData[0] = getDefaultFigmaScreenEventData();
    initialData.nodeEventData[0].nodeId = data.prototypeData?.startNodeId as string;
    initialData.path = [data.prototypeData?.startNodeId as string];
  }

  return initialData;
}

function getDefaultFigmaScreenEventData(): FigmaScreenEventData {
  return {
    nodeId: "",
    timeSpent: 0,
    clicks: [],
    overlays: [],
  };
}

function getDefaultFigmaPrototypeData(withPrototypeLink: boolean): FigmaPrototypeData {
  return {
    nodeEventData: withPrototypeLink ? {} : { 0: getDefaultFigmaScreenEventData() },
    currentNode: 0,
    path: [],
    currentNodeResponseStart: null,
    responseStart: null,
    withVideo: false,
    devicePixelRatio: window.devicePixelRatio,
    size: null,
    recordResult: null,
  };
}


interface FigmaScreenEventData {
  nodeId: string;
  timeSpent: number;
  clicks: IClick[];
  overlays: OverlayAction[];
}

export interface FigmaPrototypeData {
  nodeEventData: { [key: number]: FigmaScreenEventData };
  currentNode: number;
  path: string[];
  currentNodeResponseStart: number | null;
  responseStart: number | null;
  withVideo: boolean;
  devicePixelRatio: number;
  size: any | null;
  recordResult: RecordResult | null;
}

// This reducer allows partial updates because of the way we update the state (merging). See updateFigmaData function. 
export type figmaDataReducer = (currentFD: FigmaPrototypeData) => {
  nodeEventData?: Partial<{ [K in keyof FigmaPrototypeData['nodeEventData']]: Partial<FigmaScreenEventData> }>;
} & Partial<Omit<FigmaPrototypeData, 'nodeEventData'>>;