import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ILiveTestingResponse } from "../../../../models/Response";
import { IconCancel, IconTick } from "../../../../icons";
import { ResponseVideoData, VideoStatus } from "../../ResponseVideoData";

interface ITableRowProps {
  sharingToken?: string,
  response: ILiveTestingResponse,
  selecteTester: (response: ILiveTestingResponse) => void,
  isReportHasVideo: boolean,
}

export default function TableIndividualReportRow(props: ITableRowProps) {
  const { t } = useTranslation();

  const handlePlay = useCallback(() => {
    props.selecteTester(props.response);
  }, [props.selecteTester]);

  const videoData = new ResponseVideoData(props.response);

  return (
    <tr key={props.response.answerId} className="table-row border-t-1 border-gray-200">
      <td className="table-row__cell table-row__cell--date p-3 text-sm">
        {props.response.completedAt.toString()}
      </td>
      <td className="table-row__cell table-row__cell--status px-3 py-2 text-sm">
        {props.response.givenUp ? (
          <span className="table-row__status table-row__status--failed text-orange-600 flex items-center gap-1">
            <IconCancel className="table-row__icon table-row__icon--cancel fill-current text-red-500" width={16} height={16} />
            {t("Gave up")}
          </span>
        ) : (
          <span className="table-row__status table-row__status--success flex items-center text-green-600">
            <IconTick className="table-row__icon table-row__icon--tick fill-current mr-1" width={16} height={16} />
            {t("Succeeded")}
          </span>
        )}
      </td>
      <td className="table-row__cell table-row__cell--time px-3 py-2 text-sm">
        {props.response.time} {t("s")}
      </td>
      {props.isReportHasVideo &&
        <td className="table-row__cell table-row__cell--video px-3 py-2 text-sm">
          <button className="figma-bytester-item__result flex items-center gap-2" onClick={handlePlay} disabled={videoData.videoStatus !== VideoStatus.DONE}>
            {videoData.statusIcon}
          </button>
        </td>
      }
    </tr>
  );
}