import React from "react";
import clsx from "clsx";
import _ from "lodash";
import { motion } from "framer-motion";

import "../../../../../../tailwind.generated.css";

import TestBranding from "../TestBranding";
import { PermissionType } from "../BlockSettings/models/types";

import { locale, interfaceText } from "../../../../../../helpers";
import { usePermissionsContext } from "../BlockSettings/context/PermissionsContext";

import { useViewportUnits } from "../../../../../Common/Hooks/useViewportUnits";
import useIsMobile from "../../hooks/useIsMobile";
import useStepNavigation, { TaskStep } from "../FigmaBlock/hooks/useStepNavigation";
import PermissionScreen from "../BlockSettings/PermissionScreen";
import TaskPassing from "./TaskPassing";
import { IWebsiteData } from "../../../../../../actions/WebsiteAPI";
import FinalTaskScreen from "./FinalTaskScreen";

interface TaskProps {
  description?: React.ReactNode;
  text: React.ReactNode;
  blockId: string;
  withAudio: boolean;
  withCamera: boolean;
  withVideo: boolean;
  websiteData: IWebsiteData;
  onStart: () => void;
  isMissionInProgress: boolean;
  handleOpenWebsite: () => void;
  handleAnswer: (answerValue: boolean) => void;
}

export default function Task(props: TaskProps) {
  const isMobile = useIsMobile();

  const {
    permissions,
    requestPermission,
    isInitialPermissionsLoaded,
  } = usePermissionsContext();
  const { step, updateStep, allSteps, currentScreenStep } = useStepNavigation(
    props.withAudio,
    props.withCamera,
    props.withVideo,
    isMobile,
    permissions,
    isInitialPermissionsLoaded,
  );

  async function handleRequestPermissions(permissionType: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA]) {
    await requestPermission(permissionType);
    updateStep();
  }

  return (
    <div className={clsx("task__wrapper flex flex-col w-full h-full p-5 gap-6")}>
      <TestBranding />
      <div className="task__inside-wrapper flex flex-col w-full h-full justify-center items-center ">
        <div className={clsx("task__container flex flex-col w-full max-w-[480px]", isMobile && "h-full")}>
          <div className={"task__instructions flex flex-1 flex-col gap-3 w-full"}>
            {(step === TaskStep.ALL_SET_STEP_WITH_TASK_TEXT) && (
              <motion.div
                initial={{ opacity: 0, scale: 0.97 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.15, delay: 0.25 }}
                className="flex flex-col gap-8 h-full"
              >
                <TaskPassing
                  isMissionInProgress={props.isMissionInProgress}
                  description={props.description}
                  text={props.text}
                  blockId={props.blockId}
                  withVideo={props.withVideo}
                  withAudio={props.withAudio}
                  withCamera={props.withCamera}
                  websiteData={props.websiteData}
                  onStart={props.onStart}
                />
                {props.isMissionInProgress && <FinalTaskScreen
                  handleOpenWebsite={props.handleOpenWebsite}
                  handleAnswer={props.handleAnswer}
                  websiteData={props.websiteData} />}
              </motion.div>
            )}
          </div>

          {step === TaskStep.AUDIO_AND_CAMERA_PERMISSIONS_STEP && (
            <PermissionScreen
              isMobile={isMobile}
              updateStep={updateStep}
              handleRequestPermissions={handleRequestPermissions}
              permissionType={[PermissionType.AUDIO, PermissionType.CAMERA]}
              title={interfaceText.figmaTestingMission[locale()].allowAudioAndCameraRecording}
              description={interfaceText.figmaTestingMission[locale()].audioAndCameraPermissionDescription}
              step={currentScreenStep}
              allSteps={allSteps}
            />
          )}

          {step === TaskStep.AUDIO_PERMISSION_STEP && (
            <PermissionScreen
              isMobile={isMobile}
              updateStep={updateStep}
              handleRequestPermissions={handleRequestPermissions}
              permissionType={PermissionType.AUDIO}
              title={interfaceText.figmaTestingMission[locale()].allowAudioRecording}
              description={interfaceText.figmaTestingMission[locale()].audioAndCameraPermissionDescription}
              step={currentScreenStep}
              allSteps={allSteps}
            />
          )}

          {step === TaskStep.CAMERA_PERMISSION_STEP && (
            <PermissionScreen
              isMobile={isMobile}
              updateStep={updateStep}
              handleRequestPermissions={handleRequestPermissions}
              permissionType={PermissionType.CAMERA}
              title={interfaceText.figmaTestingMission[locale()].allowCameraRecording}
              description={interfaceText.figmaTestingMission[locale()].audioAndCameraPermissionDescription}
              step={currentScreenStep}
              allSteps={allSteps}
            />
          )}

          {step === TaskStep.SCREEN_PERMISSION_STEP && (
            <PermissionScreen
              isMobile={isMobile}
              updateStep={updateStep}
              handleRequestPermissions={handleRequestPermissions}
              permissionType={PermissionType.SCREEN}
              title={interfaceText.figmaTestingMission[locale()].allowScreenSharing}
              description={interfaceText.figmaTestingMission[locale()].screenPermissionDescription}
              withAudio={props.withAudio}
              withCamera={props.withCamera}
              step={currentScreenStep}
              allSteps={allSteps}
            />
          )}
        </div>
      </div>
    </div>
  );
};
