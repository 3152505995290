import React from "react";
import { useTranslation } from "react-i18next";

export default function MedianHint() {
  const { t } = useTranslation();
  return (
    <>
      {t("Median is the value separating the higher half from the lower half of testers responses.")}
      <br />
      <br />
      {t("It is generally unaffected by outliers: responses with a very long response time.")}
    </>
  );
}