import { BlockType } from "../../../../../../../models/Test/BlockType";

export function getRecordingPath(blockType: BlockType): string {
  if (blockType === BlockType.figma) {
    return 'figmarecordings';
  }
  if (blockType === BlockType.livetesting) {
    return 'live-testing-recordings';
  }
  throw new Error('Unknown recording type');
}