import React, { useState } from "react";
import parse from "html-react-parser";
import _ from "lodash";

import { LivetestingBlock } from "../../../../../../models/Test";
import useIsMobile from "../../hooks/useIsMobile";
import { usePermissionsContext } from "../BlockSettings/context/PermissionsContext";
import { PermissionType } from "../BlockSettings/models/types";
import FaceRecordingContainer from "../BlockSettings/FaceRecordingContainer";
import useRecording from "../BlockSettings/hooks/useRecording";
import Task from "./Task";
import Loader from "../../../../../Loader";


interface LiveTestingProps {
  data: LivetestingBlock;
  answerId: string;
  isPreview: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleSubmitBlockAnswer: (answer: any) => void;
}

export default function LiveTesting(props: LiveTestingProps) {
  const isMobile = useIsMobile();
  const data = props.data;
  const { screenStreamRef, blockPermissionSettings } = usePermissionsContext();
  const shouldRenderCameraVideo = (blockPermissionSettings[PermissionType.CAMERA] || blockPermissionSettings[PermissionType.AUDIO]);
  const showVideoContainer = blockPermissionSettings[PermissionType.CAMERA] && !isMobile;
  const [isMissionInProgress, setIsMissionInProgress] = useState(false);
  const [blockStartedAt, setBlockStartedAt] = useState<Date | null>(null);
  const isCameraRecordingAvailable = isMobile ? false : !!props.data.withCamera;

  const recording = useRecording({
    permissions: blockPermissionSettings,
    screenStreamRef: screenStreamRef!,
    options: { blockId: data.blockId, answerId: props.answerId },
    prototypeLinkExists: false,
    blockType: props.data.type,
    isBlockWithVideo: !!props.data.withVideo,
  });

  function onStart() {
    setIsMissionInProgress(true);
    setBlockStartedAt(new Date());
    openWebsite();
    recording.start();
  }

  function openWebsite() {
    if (data.websiteData?.url) {
      window.open(data.websiteData.url, "_blank", "noopener,noreferrer");
    }
  }

  async function submitBlockAnswer(isSuccess: boolean) {
    const recordResult = await recording.finish();

    const now = _.now();
    const totalTimeSpent = now - blockStartedAt!.getTime();

    const answerData = {
      givenUp: !isSuccess,
      time: totalTimeSpent,
      responseTime: totalTimeSpent,
      recordResult,
    };
    props.handleSubmitBlockAnswer(answerData);
  }

  return (
    <div className="w-full h-full flex touch-none justify-center items-center flex-col" id="testContentBlock">
        {recording.isUploading
          ? <Loader />
          : <Task
            description={data.description ? parse(data.description) : undefined}
            text={parse(data.questionHtml || data.text)}
            blockId={data.blockId}
            withVideo={!!data.withVideo}
            withAudio={!!data.withAudio}
            withCamera={isCameraRecordingAvailable}
            websiteData={data.websiteData}
            onStart={onStart}
            isMissionInProgress={isMissionInProgress}
            handleOpenWebsite={openWebsite}
            handleAnswer={submitBlockAnswer}
          />}
        {shouldRenderCameraVideo && !recording.isUploading &&
          <FaceRecordingContainer
            isPreview={props.isPreview}
            videoFaceRef={recording.videoFaceRef}
            isRecording={recording.isRecording}
            showVideoContainer={showVideoContainer}
          />
        }
    </div>
  );
};
