import React from 'react';
import { t } from 'i18next';

import { IconHire } from '../../../icons';
import { IconDropoff } from '../../../icons';
import MedianHint from '../MedianHint';
import { calcMedian, msToSeconds } from '../utils';


export default function useTaskCompletionStats<T extends { time: number, givenUp: boolean }>(responses: T[]) {
	const responseTimes: number[] = [];
	let completionTime = 0;
	let gaveUpTotal = 0;

	for (let index = 0; index < responses.length; index++) {
		const response = responses[index];
		completionTime += response.time;
		responseTimes.push(response.time);

		if (response.givenUp) {
			gaveUpTotal++;
		}
	}

	// calculate average response time
	const averageCompletionTime = msToSeconds(completionTime / responses.length, 2);

	// calculate median response time
	const medianCompletionTime = msToSeconds(calcMedian(responseTimes), 2);

	// const gaveUpTotal = responses.reduce((sum, response) => (response.givenUp === true ? sum + 1 : sum), 0);
	const succeedTotal = responses.length - gaveUpTotal;

	const commonStatsInfo = [
    [
      t("Succeeded"),
      <div className="flex items-center gap-2">
        <IconHire className="w-6 h-6 fill-current text-green-500" /> {succeedTotal}
      </div>,
    ],
    [t("Gave Up"), <div className="flex items-center gap-2">
      <IconDropoff className="w-6 h-6 fill-current text-orange-600" />{gaveUpTotal}
    </div>],
    [
      t("Average time"),
      <div className="flex items-center gap-2">
        {averageCompletionTime} {t("s")}
      </div>,
    ],
    [
      t("Median time"),
      <div className="flex items-center gap-2">
        {medianCompletionTime} {t("s")}
      </div>,
      <MedianHint />
    ],
  ];

	return {
		averageCompletionTime,
		medianCompletionTime,
		gaveUpTotal,
		succeedTotal,
		commonStatsInfo,
	};
}