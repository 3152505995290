import { useEffect } from "react";

interface UseKeyNavigationOptions<T> {
  items: T[];
  selectedItem: T;
  isItemValid?: (item: T) => boolean;
  getItemId: (item: T) => string;
  onSelect: (item: T) => void;
  onEscape?: () => void;
  isEnabled?: boolean;
}

export default function useKeyNavigation<T>(props: UseKeyNavigationOptions<T>) {
  function getNextItem(direction: 'next' | 'prev'): T | null {
    const currentIndex = props.items.findIndex(item => 
      props.getItemId(item) === props.getItemId(props.selectedItem)
    );

    let newIndex = currentIndex;

    do {
      newIndex = direction === 'next' ? newIndex + 1 : newIndex - 1;
      const item = props.items[newIndex];
      if (item && props.isItemValid?.(item)) {
        return item;
      }
    } while (newIndex >= 0 && newIndex < props.items.length);

    return null;
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        const direction = event.key === 'ArrowRight' ? 'next' : 'prev';
        const nextItem = getNextItem(direction);

        if (nextItem) {
          props.onSelect(nextItem);
        }
      }

      if (event.key === "Escape" && props.onEscape) {
        props.onEscape();
      }
    };

    if (props.isEnabled) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (props.isEnabled) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [props.items, props.selectedItem, props.isEnabled, props.onSelect, props.onEscape]);
}

