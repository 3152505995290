
import React from "react";
import { motion } from "framer-motion";
import useDraggable from "./hooks/useDraggable";

interface IFaceRecordingContainerProps {
  videoFaceRef: React.MutableRefObject<HTMLVideoElement | null>;
  isRecording: boolean;
  isPreview: boolean;
  showVideoContainer: boolean;
}

export default function FaceRecordingContainer(props: IFaceRecordingContainerProps) {
  const { position, isDragging, handleMouseDown } = useDraggable(props.isPreview);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.25, delay: 0.25 }}
      id="figma-face-recording-container"
      className={
        `video-container overflow-hidden absolute rounded-md`
      }
      style={{
        zIndex: 1002,
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        opacity: props.showVideoContainer ? 1 : 0,
        pointerEvents: props.showVideoContainer ? 'auto' : 'none',
        width: props.showVideoContainer ? '116px' : '0',
        height: props.showVideoContainer ? '116px' : '0',
      }}
      onMouseDown={handleMouseDown}
    >
      <motion.div
        className="absolute top-2 right-2 bg-red-500 p-1 z-20 h-2 w-2 rounded-md"
        animate={props.isRecording ? { opacity: [1, 0, 1] } : { opacity: 1 }}
        transition={props.isRecording ? { duration: 1, repeat: Infinity } : {}}
      />
      <video
        className="rounded-md"
        style={{ objectFit: "cover", height: "100%", width: "100%", borderRadius: "16px" }}
        preload='auto'
        ref={props.videoFaceRef}
        autoPlay
        muted={true}
      />
    </motion.div>
  )
}