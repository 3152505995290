import { useEffect, useRef, useState } from "react";

export default function useVideoContent(recordingFolder: string, recordingId: string, isScreenRecord: boolean, isCameraRecord: boolean, isMicrophoneRecord: boolean, sharingToken?: string) {
  const videoFaceRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoFaceError, setVideoFaceError] = useState(false);
  const [videoScreenError, setVideoScreenError] = useState(false);
  const [isPlayerLoading, setIsPlayerLoading] = useState(true);

  const withRespondentRecord = isCameraRecord || isMicrophoneRecord;
  const [loadedVideoCount, setLoadedVideoCount] = useState(withRespondentRecord ? 2 : 1);

  const videoCanvasUrl = `/api/v1/answers/${recordingFolder}/${recordingId}/video?sharingToken=${sharingToken}`;
  const videoScreenUrl = `/api/v1/answers/${recordingFolder}/${recordingId}/screen/video?sharingToken=${sharingToken}`;

  const videoUrl = isScreenRecord ? videoScreenUrl : videoCanvasUrl;
  const videoFaceUrl = `/api/v1/answers/${recordingFolder}/${recordingId}/respondent/video?sharingToken=${sharingToken}`;

  function handleVideoError(type: "screen" | "face") {
    if (type === "screen") {
      setVideoScreenError(true);
    } else {
      setVideoFaceError(true);
    }

    handleReduceLoadedVideoCount();
  }

  function handleReduceLoadedVideoCount() {
    setLoadedVideoCount(prev => prev - 1);
  }

  useEffect(() => {
    if (loadedVideoCount <= 0) {
      setIsPlayerLoading(false);
    }
  }, [loadedVideoCount]);

  return {
    videoUrl,
    videoFaceUrl,
    videoFaceRef,
    videoRef,
    videoFaceError,
    videoScreenError,
    withRespondentRecord,
    isPlayerLoading,
    setIsPlayerLoading,
    handleVideoError,
    loadedVideoCount,
    handleReduceLoadedVideoCount,
  }

}