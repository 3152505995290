import React, { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import Preview from "./Preview";
import Tabs, { TabItem } from "../../Tabs";
import Modal from "../../Modal";

import { IReportState, IReportStateByTester } from './FigmaReport';
import VideoPlayerResolver from "./Player/VideoPlayerResolver";

import { IFormattedPrototype } from '../../../utils/figma';

import isAnswerHasVideo from "../utils/isAnswerHasVideo";
import TableIndividualReportRow from "./Table/TableIndividualReportRow";
import useKeyNavigation from "../hooks/useKeyNavigation";

export interface IFigmaReportViewProps {
  blockId: string;
  byScreen: IReportState["byScreen"];
  byTester: IReportState["byTester"];
  onPreviewClick: (path: (string | number)[]) => void;
  prototype: IFormattedPrototype;
  sharingToken?: string;
  isSummaryReport: boolean;
}

export default function FigmaPrototypeScreensReport(props: IFigmaReportViewProps) {
  const { t } = useTranslation();
  return (
    <div className='figma-screens-report flex flex-col gap-3'>
      {props.isSummaryReport && <div className="figma-screens-report__summary flex flex-col gap-1">
        <div className="figma-screens-report__summary-header flex gap-2">
          <div className="figma-screens-report__summary-content flex flex-col gap-1">
            <div className="figma-screens-report__summary-title text-gray-800 text-base+ font-medium">{t("Heatmaps and clicks")}</div>
            <span className="figma-screens-report__summary-description text-gray-600 text-sm block">{t("See heatmaps and clicks for each screen or individual responses")}</span>
          </div>
        </div>
      </div>}
      {props.isSummaryReport ? (
        <Tabs>
          <TabItem label={t("By Screen")}>
            <FigmaReportByScreen
              byScreen={props.byScreen}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype} />
          </TabItem>
          <TabItem label={t("By Tester")}>
            <FigmaReportByTester
              blockId={props.blockId}
              byTester={props.byTester}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype}
              sharingToken={props.sharingToken}
              isSummaryReport={props.isSummaryReport}
            />
          </TabItem>
        </Tabs>
      ) : (
        <TabItem label={t("By Tester")}>
          <FigmaReportByTester
            blockId={props.blockId}
            byTester={props.byTester}
            onPreviewClick={props.onPreviewClick}
            prototype={props.prototype}
            sharingToken={props.sharingToken}
            isSummaryReport={props.isSummaryReport}
          />
        </TabItem>
      )}
    </div>
  );
}

function FigmaReportByTester(props: Omit<IFigmaReportViewProps, 'byScreen'>) {
  const isReportHasVideo = props.byTester.some(isAnswerHasVideo);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedTester, setSelectedTester] = useState<IReportStateByTester>(props.byTester[0]);

  useKeyNavigation<IReportStateByTester>({
    items: props.byTester,
    selectedItem: selectedTester,
    isItemValid: isAnswerHasVideo,
    getItemId: (item) => item.answerId,
    onSelect: (tester) => setSelectedTester(tester),
    onEscape: () => setIsVideoModalOpen(false),
    isEnabled: props.isSummaryReport
  });

  return (
    <div className="figma-bytester-list border-1 border-gray-250 rounded-lg overflow-x-auto overflow-y-hidden px-4">
      <table className="figma-bytester-list__table table-auto w-full border-collapse min-w-[400px]" aria-label="Example static collection table">
        <thead className="figma-bytester-list__header text-sm captionNoMargin">
          <tr>
            <th className="figma-bytester-list__header-date px-3 py-2 font-medium min-w-[80px] text-left">{t("Date")}</th>
            <th className="figma-bytester-list__header-status px-3 py-2 font-medium w-[100px] text-left">{t("Status")}</th>
            <th className="figma-bytester-list__header-time px-3 py-2 font-medium w-[100px] text-left">{t("Time")}</th>
            <th className="figma-bytester-list__header-path px-3 py-2 font-medium min-w-[120px] text-left">{t("Path")}</th>
            {isReportHasVideo && <th className="figma-bytester-list__header-replay py-2 px-3 shrink-0 text-left font-medium">{t("Replay")}</th>}
          </tr>
        </thead>
        <tbody>
          {props.byTester.map((tester, index) => (
            <TableIndividualReportRow
              key={`FigmaIndividualReport-${index}`}
              blockId={props.blockId}
              tester={tester}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype}
              pixelRatio={tester.devicePixelRatio || 1}
              size={tester.size}
              userAgent={tester.userAgent}
              prototypeType={tester.prototypeType}
              sharingToken={props.sharingToken}
              selectTester={(tester) => {
                setSelectedTester(tester);
                setIsVideoModalOpen(true);
              }}
              isReportHasVideo={isReportHasVideo}
            />
          ))}
        </tbody>
      </table>

      <Modal width="95vw" height="90vh" isOpen={isVideoModalOpen} setIsOpen={setIsVideoModalOpen} background>
        <VideoPlayerResolver
          size={selectedTester.size}
          prototypeType={selectedTester.prototypeType}
          tester={selectedTester}
          closeModal={() => setIsVideoModalOpen(false)}
          screenVideo={false}
          blockId={props.blockId}
          pixelRatio={selectedTester.devicePixelRatio || 1}
          userAgent={selectedTester.userAgent}
          isVideoScrollEnabled={props.isSummaryReport}
        />
      </Modal>
    </div>
  );
}

function FigmaReportByScreen(props: Pick<IFigmaReportViewProps, 'byScreen' | 'onPreviewClick' | 'prototype'>) {
  return (
    <div className="flex flex-wrap mt-8 gap-4">
      {props.byScreen.screens.map((screen, index) => (
        <Preview
          key={index}
          prototype={props.prototype}
          screen={screen}
          width={96}
          onClick={() => props.onPreviewClick(screen.path)}
        />
      ))}
    </div>
  );
}

FigmaPrototypeScreensReport.propTypes = {
  blockId: PropTypes.string,
  byScreen: PropTypes.shape({
    screens: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        path: PropTypes.array,
        nextScreenPath: PropTypes.array,
        prevScreenPath: PropTypes.array,
        clicks: PropTypes.array,
        stats: PropTypes.array,
      })
    ),
  }),
  byTester: PropTypes.arrayOf(
    PropTypes.shape({
      answerId: PropTypes.string,
      givenUp: PropTypes.bool,
      time: PropTypes.string,
      number: PropTypes.number,
      screens: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          image: PropTypes.string,
          path: PropTypes.array,
          nextScreenPath: PropTypes.array,
          prevScreenPath: PropTypes.array,
          clicks: PropTypes.array,
          stats: PropTypes.array,
        })
      ),
    })
  ),
  onPreviewClick: PropTypes.func,
};