import React from "react";
import "../tailwind.generated.css";

import { ReactComponent as WarningIcon } from "../icons/error.svg";
import { ReactComponent as InfoIcon } from "../icons/info.svg";

import clsx from "clsx";

const iconMap = {
  warning: WarningIcon,
  error: WarningIcon,
  info: InfoIcon,
}

interface IBannerProps {
  icon?: JSX.Element;
  text?: string | JSX.Element;
  type: "warning" | "error" | "info";
  header: string;
  className?: string;
  small?: boolean;
}

const Banner = (props: IBannerProps) => {
  const { icon, text, type, header, className, small } = props;
  const IconComponent = iconMap[type];

  return (
    <div
      className={clsx(
        "w-full text-gray-900 rounded-lg",
        small ? "p-2" : "p-3",
        type === "warning" && "bg-orange-200",
        type === "error" && "bg-red-200",
        type === "info" && "bg-blue-100",
        className && className
      )}
    >
      <div className={clsx("banner__icon mr-2 flex items-start gap-3", header ? "items-start" : "items-center")}>
      {icon ? icon : <IconComponent className="fill-current text-gray-800 shrink-0" width={24} height={24} />}
        <div className={clsx("banner__text flex flex-col gap-[4px]", small ? "gap-[2px]" : "gap-[4px]")}>
          {header && <span className="banner__header font-medium block">{header}</span>}
          {text && <span className="banner__text block text-sm font-normal">{text}</span>}
        </div>
      </div>
    </div>
  );
};

export default Banner;
