import React, { useState } from "react";
import { IconWebsite } from "../../../../../../icons";
import clsx from "clsx";

interface IWebsiteFaviconProps {
  faviconUrl: string | null;
  faviconIconClassName?: string;
}

export default function WebsiteFavicon({ faviconUrl, faviconIconClassName }: IWebsiteFaviconProps) {
  const [errorLoading, setErrorLoading] = useState(false);

  if (!faviconUrl || errorLoading) return <IconWebsite className={clsx("website-preview__favicon-placeholder w-4 h-4", faviconIconClassName)} />;

  return (
    <img
      src={faviconUrl}
      alt="website favicon"
      className={clsx("website-favicon w-4 h-4", faviconIconClassName)}
      onError={() => setErrorLoading(true)}
    />
  );
}