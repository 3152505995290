import { BlockType } from "../../../models/Test";

export const baseConditions: Record<BlockType, Array<{ name: string; value: string }>> = {
  openquestion: [
    { name: "has answer", value: "hasAnswer" },
    { name: "contains", value: "contains" },
    { name: "does not contain", value: "doesNotContain" },
  ],
  choice: [
    { name: "has answer", value: "hasAnswer" },
    { name: "contains", value: "contains" },
    { name: "does not contain", value: "doesNotContain" },
  ],
  scale: [
    { name: "has answer", value: "hasAnswer" },
    { name: "is", value: "is" },
  ],
  preference: [
    { name: "has answer", value: "hasAnswer" },
    { name: "contains", value: "contains" },
    { name: "does not contain", value: "doesNotContain" },
  ],
  figma: [
    { name: "has answer", value: "hasAnswer" },
    { name: "path includes", value: "contains" },
    { name: "completed on...", value: "completedOn" },
    { name: "not completed", value: "notCompleted" },
  ],
  cardsort: [{ name: "has answer", value: "hasAnswer" }],
  firstclick: [{ name: "has answer", value: "hasAnswer" }],
  context: [],
  fiveseconds: [],
  matrix: [],
  ai: [],
  livetesting: [
    { name: "completed", value: "completed" },
    { name: "not completed", value: "notCompleted" },
  ],
};

export const sourceFilterValues = [
  { name: "the link", value: "link" },
  { name: "the participants pool", value: "toloka" },
  { name: "custom panels", value: "customPanel" },
];

export const urlParameterConditions = [
  {
    name: "is in answer",
    value: "inAnswer",
  },
  {
    name: "is",
    value: "is",
  },
  { name: "contains", value: "contains" },
];
