import React, { useState, useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import { motion } from "framer-motion";

import "../../../../../../tailwind.generated.css";

import Modal from "../../../../../Modal";
import Button from "../../../../../Button";

import { locale, interfaceText } from "../../../../../../helpers";

import TestBranding from "../TestBranding";
import ShowTaskButton from "../ShowTask";
import BlockRecordingSettings from "../BlockSettings/BlockRecordingSettings";

import { usePermissionsContext } from "../BlockSettings/context/PermissionsContext";
import useIsMobile from "../../hooks/useIsMobile";
import useStepNavigation, { TaskStep } from "./hooks/useStepNavigation";
import { useViewportUnits } from "../../../../../Common/Hooks/useViewportUnits";

import { PermissionType } from "../BlockSettings/models/types";
import PermissionScreen from "../BlockSettings/PermissionScreen";

interface TaskProps {
  description?: React.ReactNode;
  text: React.ReactNode;
  onStart: () => void;
  onGiveUp: () => void;
  isLoading: boolean;
  blockId: string;
  withAudio: boolean;
  withCamera: boolean;
  withVideo: boolean;
}

export default function Task(props: TaskProps) {
  const isMobile = useIsMobile();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);
  const [isMissionInProgress, setIsMissionInProgress] = useState(false);

  const viewportUnit = useViewportUnits();

  const {
    permissions,
    requestPermission,
    isInitialPermissionsLoaded,
  } = usePermissionsContext();
  const { step, updateStep, allSteps, currentScreenStep } = useStepNavigation(
    props.withAudio,
    props.withCamera,
    props.withVideo,
    isMobile,
    permissions,
    isInitialPermissionsLoaded,
  );

  useEffect(() => {
    setIsTaskModalOpen(true);
    setIsMissionInProgress(false);
  }, [props.blockId]);


  async function handleRequestPermissions(permissionType: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA]) {
    await requestPermission(permissionType);
    updateStep();
  }

  function handleStart() {
    if (!props.isLoading) {
      setIsTaskModalOpen(false);
      setIsMissionInProgress(true);
      if (!isMissionInProgress) {
        props.onStart();
      }
    }
  }

  return (
    <>
      {isMissionInProgress && (
        <ShowTaskButton
          openTaskModal={() => setIsTaskModalOpen(true)}
          buttonName={interfaceText.test[locale()].showTask}
        />
      )}
      <Modal
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
        width="480px"
        disallowClickOutside
        background
        sidebarMode
        backdrop
      >
        <div className="flex flex-col flex-1 h-full p-5">
          <div className={clsx("flex h-full flex-col gap-6")}>
            <div className="task__instructions flex flex-1 flex-col h-full gap-3">
              <TestBranding />
              {(step === TaskStep.ALL_SET_STEP_WITH_TASK_TEXT) && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.25, delay: 0.25 }}
                  className="flex flex-col gap-4 h-full justify-between"
                >
                  <div
                    className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden"
                    style={{ maxHeight: isMobile ? `60${viewportUnit}` : 'auto', wordWrap: "break-word" }}
                  >
                    <span className="block text-lg mt-2 font-medium">{props.text}</span>
                    <span className="block mt-2">{props.description}</span>
                  </div>

                  <div className="flex flex-col gap-4 items-end">
                    {!isMissionInProgress && (props.withAudio || props.withCamera || (props.withVideo && !isMobile)) && (
                      <BlockRecordingSettings
                        withAudio={props.withAudio}
                        withCamera={props.withCamera}
                        withVideo={props.withVideo}
                      />
                    )}

                    <div className={clsx(`figma-task__controls flex items-center w-full`, {
                      "justify-between": isMissionInProgress,
                      "justify-end": !isMissionInProgress,
                      "flex-col gap-4": isMobile,
                    })}
                    >
                      {isMissionInProgress && (
                        <Button
                          large
                          type="secondary"
                          handler={() => {
                            props.onGiveUp();
                          }}
                          name={interfaceText.figmaTesting[locale()].giveUpButton}
                          fullWidth={isMobile}
                        />
                      )}

                      <Button
                        className={`figma-task__button-start ${props.isLoading ? "" : "active"}`}
                        name={
                          props.isLoading
                            ? interfaceText.figmaTestingMission[locale()].loading
                            : isMissionInProgress
                              ? interfaceText.figmaTestingMission[locale()].buttonWhenMissionStarted
                              : interfaceText.figmaTestingMission[locale()].button
                        }
                        handler={handleStart}
                        large
                        disabled={props.isLoading}
                        type="primary"
                        fullWidth={isMobile}
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </div>

            {step === TaskStep.AUDIO_AND_CAMERA_PERMISSIONS_STEP && (
              <PermissionScreen
                isMobile={isMobile}
                updateStep={updateStep}
                handleRequestPermissions={handleRequestPermissions}
                permissionType={[PermissionType.AUDIO, PermissionType.CAMERA]}
                title={interfaceText.figmaTestingMission[locale()].allowAudioAndCameraRecording}
                description={interfaceText.figmaTestingMission[locale()].audioAndCameraPermissionDescription}
                step={currentScreenStep}
                allSteps={allSteps}
              />
            )}

            {step === TaskStep.AUDIO_PERMISSION_STEP && (
              <PermissionScreen
                isMobile={isMobile}
                updateStep={updateStep}
                handleRequestPermissions={handleRequestPermissions}
                permissionType={PermissionType.AUDIO}
                title={interfaceText.figmaTestingMission[locale()].allowAudioRecording}
                description={interfaceText.figmaTestingMission[locale()].audioAndCameraPermissionDescription}
                step={currentScreenStep}
                allSteps={allSteps}
              />
            )}

            {step === TaskStep.CAMERA_PERMISSION_STEP && (
              <PermissionScreen
                isMobile={isMobile}
                updateStep={updateStep}
                handleRequestPermissions={handleRequestPermissions}
                permissionType={PermissionType.CAMERA}
                title={interfaceText.figmaTestingMission[locale()].allowCameraRecording}
                description={interfaceText.figmaTestingMission[locale()].audioAndCameraPermissionDescription}
                step={currentScreenStep}
                allSteps={allSteps}
              />
            )}

            {step === TaskStep.SCREEN_PERMISSION_STEP && (
              <PermissionScreen
                isMobile={isMobile}
                updateStep={updateStep}
                handleRequestPermissions={handleRequestPermissions}
                permissionType={PermissionType.SCREEN}
                title={interfaceText.figmaTestingMission[locale()].allowScreenSharing}
                description={interfaceText.figmaTestingMission[locale()].screenPermissionDescription}
                withAudio={props.withAudio}
                withCamera={props.withCamera}
                step={currentScreenStep}
                allSteps={allSteps}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

