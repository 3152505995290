import React, { useState } from "react";
import clsx from "clsx";
import _ from "lodash";

import "../../../../../../tailwind.generated.css";

import Button from "../../../../../Button";
import { BlockRecordingSettingsMinimalView } from "../BlockSettings/BlockRecordingSettings";
import { IWebsiteData } from "../../../../../../actions/WebsiteAPI";

import { locale, interfaceText } from "../../../../../../helpers";
import useIsMobile from "../../hooks/useIsMobile";
import WebsiteFavicon from "./WebsiteFavicon";


interface TaskPassingProps {
  description?: React.ReactNode;
  text: React.ReactNode;
  onStart: () => void;
  websiteData: IWebsiteData;
  blockId: string;
  withAudio: boolean;
  withCamera: boolean;
  withVideo: boolean;
  isMissionInProgress: boolean;
}

export default function TaskPassing(props: TaskPassingProps) {
  const showRecording = props.withCamera || props.withAudio || props.withVideo;
  const isMobile = useIsMobile(); 

  return (
    <div className={clsx("task-passing flex flex-col gap-8", isMobile && "h-full justify-between")} >
      <div className="task-passing__content w-full h-full flex flex-col gap-3">
        <p className="task-passing__text text-lg font-medium">{props.text}</p>
        <div className="task-passing__description text-sm text-gray-800">{props.description}</div>
      </div>
      <div className={clsx("task-passing__controls flex justify-between")}>
        {showRecording && <BlockRecordingSettingsMinimalView
          withAudio={props.withAudio}
          withCamera={props.withCamera}
          withVideo={props.withVideo}
          isDisabled={props.isMissionInProgress} />}
        {!props.isMissionInProgress && <div className="task-passing__button-wrapper flex w-full gap-2 justify-end">
          <Button
            icon={<WebsiteFavicon faviconIconClassName="mr-1 rounded-[4px]" faviconUrl={props.websiteData?.faviconUrl} />}
            name={`${interfaceText.test[locale()].goTo} ${props.websiteData?.hostname} →`}
            handler={props.onStart}
            type="primary"
            className="task-passing__submit-button website-link__submit"
            large
          />
        </div>}
      </div>
    </div>
  );
};
