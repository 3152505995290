export function checkIfValidFigmaPrototypeLink(link: string) {
    const regex = /^https:\/\/(www\.figma\.com\/proto\/).+$/;
    return regex.test(link);
}

export function checkIfValidWebsiteLink(link: string) {
    try {
        new URL(link);
        return true;
    } catch {
        return false;
    }
}