import React, { useState, } from "react";
import parse from "html-react-parser";
import _ from "lodash";

import "../../../../../../tailwind.generated.css";

import FigmaSuccessModal from "./FigmaSuccessModal";
import FigmaTestView from "../../../../../Figma/FigmaTestView";

import { Block, IFigmaBlockWithPrototypeData } from "../../../../../../models/Test";
import useFigmaHandlers, { FigmaPrototypeData } from './hooks/useFigmaHandlers';
import FigmaIFrame from '../../../../../Figma/FigmaIFrame';
import Task from "./Task";
import { usePermissionsContext } from "../BlockSettings/context/PermissionsContext";
import useIsMobile from "../../hooks/useIsMobile";
import { PermissionType } from "../BlockSettings/models/types";
import FaceRecordingContainer from "../BlockSettings/FaceRecordingContainer";

export interface IFigmaProps {
  data: IFigmaBlockWithPrototypeData & Block;
  testId: string;
  answerId: string;
  isPreview: boolean;
  isLoading: boolean;

  setIsLoading: (isLoading: boolean) => void;
  handleFigmaGiveUp: (data: FigmaPrototypeData) => void;
  handleFigmaSuccess: (data: FigmaPrototypeData) => void;
}

export default function Figma(props: IFigmaProps) {
  const isMobile = useIsMobile();
  const data = props.data;
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const { blockPermissionSettings } = usePermissionsContext();
  const shouldRenderCameraVideo = (blockPermissionSettings[PermissionType.CAMERA] || blockPermissionSettings[PermissionType.AUDIO]) && !isSuccessModalOpen;
  const showVideoContainer = blockPermissionSettings[PermissionType.CAMERA] && !isMobile;

  // if on, then we are recording
  const withVideo = (!!data.withVideo || !!data.withAudio || !!data.withCamera) && !props.isPreview;

  const figmaHandlers = useFigmaHandlers({
    testId: props.testId,
    blockId: data.blockId,
    answerId: props.answerId,
    withVideo,
    data: props.data,
    handleFigmaGiveUp: props.handleFigmaGiveUp,
    onGoalNodeReached: onFigmaGoalNodeReached,
  })

  function onFigmaGoalNodeReached() {
    // wait for 1000ms to show success modal
    setTimeout(() => {
      setisSuccessModalOpen(true);
    }, 1000);
  }

  function onSuccessModalClick() {
    setisSuccessModalOpen(false);
    props.handleFigmaSuccess(figmaHandlers.prototypeState as FigmaPrototypeData)
  }

  return (
    <div className="w-full h-full flex flex-col relative" id="testContentBlock">
      <div className="h-full relative touch-none">
        <Task
          description={data.description ? parse(data.description) : undefined}
          text={parse(data.questionHtml || data.text)}
          onStart={figmaHandlers.onTaskStart}
          onGiveUp={figmaHandlers.onTaskGiveUp}
          isLoading={props.isLoading}
          blockId={data.blockId}
          withVideo={!!data.withVideo}
          withAudio={!!data.withAudio}
          withCamera={!!data.withCamera}
        />
        <FigmaSuccessModal
          onClick={onSuccessModalClick}
          isOpen={isSuccessModalOpen}
          isLoading={figmaHandlers.recording.isUploading}
        />
        {data.prototypeLink && (
          <FigmaIFrame
            options={{
              fileId: data.fileId || '',
              startNodeId: data.startNodeId || '',
              fileVersion: data.fileVersion || '',
              scaling: data.scaling || "scale-down-width",
              fps: 10,
              video: withVideo === true,
            }}
            width={'100%'}
            height={'100%'}
            onLoad={() => props.setIsLoading(false)}
            onClick={(e) => {
              console.log('click', e);
              figmaHandlers.onFigmaClick(e);
            }}
            onPresentedNodeChanged={(e) => {
              console.log('presented node changed', e);
              figmaHandlers.onFigmaTransition(e);
            }}
            onSizeRetrieved={(e) => figmaHandlers.onFigmaSizeRetrieved(e)}
          />
        )}

        {/* Prototype in html format that imported with code */}
        {data.prototypeData && (
          <FigmaTestView
            className="w-full h-full bg-black"
            startNodeId={data.prototypeData.startNodeId}
            prototype={data.prototypeData}
            showDefaultCursor={!!data.showDefaultCursor}
            onLoad={() => props.setIsLoading(false)}
            onClick={figmaHandlers.onFigmaClick}
          />
        )}


        {shouldRenderCameraVideo &&
          <FaceRecordingContainer
            isPreview={props.isPreview}
            videoFaceRef={figmaHandlers.recording.videoFaceRef}
            isRecording={figmaHandlers.recording.isRecording}
            showVideoContainer={showVideoContainer}
          />
        }
      </div>
    </div>
  );

};

