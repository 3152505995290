import React from "react";
import _ from "lodash";

import "../../../../../../tailwind.generated.css";

import Button from "../../../../../Button";
import { IWebsiteData } from "../../../../../../actions/WebsiteAPI";
import { IconCheck } from "../../../../../../icons";
import { locale, interfaceText } from "../../../../../../helpers";
import WebsiteFavicon from "./WebsiteFavicon";

interface TaskProps {
  websiteData: IWebsiteData;
  handleAnswer: (answerValue: boolean) => void;
  handleOpenWebsite: () => void;
}

export default function Task(props: TaskProps) {
  return (
    <div className="flex flex-col gap-5 bg-gray-100 px-8 py-6 rounded-2xl justify-center items-center">
      <p className="text font-medium text-lg text-gray-800">{interfaceText.test[locale()].haveYouFinishedTheTask}</p>
      <div className="flex  flex-col gap-3 justify-center items-center w-full">
        <Button
          icon={<IconCheck className="text-white" />}
          name={interfaceText.test[locale()].yesIDid}
          handler={() => {
            props.handleAnswer(true);
          }}
          type="primary"
          className="website-link__submit"
          fullWidth
          large
        />
        <Button
          name={interfaceText.test[locale()].noICouldntDoThat}
          handler={() => {
            props.handleAnswer(false);
          }}
          type="secondary"
          className="website-link__submit"
          fullWidth
          large
        />
        <Button
          icon={<WebsiteFavicon faviconUrl={props.websiteData?.faviconUrl} faviconIconClassName="fill-current text-gray-900" />}
          name={`${interfaceText.test[locale()].getBackTo} ${props.websiteData?.hostname} →`}
          handler={props.handleOpenWebsite}
          type="ghost"
          className="website-link__submit"
          fullWidth
          large
        />
      </div>
    </div>
  );
};
