import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { CanvasSize } from "../../../../models/Response";
import { IReportStateByTester, PrototypeType } from "../FigmaReport";
import { IFigmaReportViewProps } from "../FigmaReportView";
import { IconCancel, IconTick } from "../../../../icons";
import Preview from "../Preview";
import { ResponseVideoData, VideoStatus } from "../../ResponseVideoData";


interface TableIndividualReportRowProps
  extends Pick<IFigmaReportViewProps, "blockId" | "onPreviewClick" | "prototype" | "sharingToken"> {
  tester: IReportStateByTester;
  pixelRatio: number;
  size?: CanvasSize;
  prototypeType: PrototypeType;
  userAgent?: string;
  selectTester: (tester: IReportStateByTester) => void;
  isReportHasVideo: boolean;
}

export default function TableIndividualReportRow(props: TableIndividualReportRowProps) {
  const { t } = useTranslation();

  const handlePlay = useCallback(() => {
    props.selectTester(props.tester);
  }, [props.selectTester, props.tester]);

  const videoData = new ResponseVideoData(props.tester)

  return (
    <tr className="report-row border-t-1 border-gray-200">
      <td className="report-row__cell report-row__cell--date px-3 py-2 text-sm">
        {props.tester.date}
      </td>
      <td className="report-row__cell report-row__cell--status px-3 py-2 text-sm">
        {props.tester.givenUp ? (
          <span className="report-row__status report-row__status--failed text-orange-600 flex items-center">
            <IconCancel className="report-row__icon report-row__icon--cancel fill-current text-red-500" width={16} height={16} />
            {t("Gave up")}
          </span>
        ) : (
          <span className="report-row__status report-row__status--success text-green-600 flex items-center">
            <IconTick className="report-row__icon report-row__icon--tick fill-current mr-1" width={16} height={16} />
            {t("Succeeded")}
          </span>
        )}
      </td>
      <td className="report-row__cell report-row__cell--time px-3 py-2 text-sm">
        {props.tester.time}
      </td>
      <td className="report-row__cell report-row__cell--preview px-3 py-4">
        <div className="report-row__previews flex flex-wrap gap-y-2">
          {props.tester.screens.map((screen, index) => (
            <Preview
              key={index}
              prototype={props.prototype}
              screen={screen}
              width={40}
              onClick={() => props.onPreviewClick(screen.path)}
              showScreenName={false}
            />
          ))}
        </div>
      </td>
      {props.isReportHasVideo && (
        <td className="report-row__cell report-row__cell--video px-3 py-2 text-sm">
          <button className="figma-bytester-item__result flex items-center gap-2" onClick={handlePlay} disabled={videoData.videoStatus !== VideoStatus.DONE}>
            {videoData.statusIcon}
          </button>
        </td>
      )}
    </tr>
  );
}
