import { t } from "i18next";
import React, { useState } from "react";

import Modal from "../../../Modal";
import TableIndividualReportRow from "./TableRow";
import ScreenRecorderPlayer from "../../FigmaReport/Player/ScreenRecorderPlayer";
import useKeyNavigation from "../../hooks/useKeyNavigation";
import isAnswerHasVideo from "../../utils/isAnswerHasVideo";
import { ILiveTestingResponse } from "../../../../models/Response";
import { BlockType } from "../../../../models/Test/BlockType";

export default function ResponsesTable(props: {
  responses: ILiveTestingResponse[],
  blockId: string,
  sharingToken?: string,
  isSummaryReport: boolean,
}) {
  const isReportHasVideo = props.responses.some(isAnswerHasVideo);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedTester, setSelectedTester] = useState<ILiveTestingResponse>(props.responses[0]);

  useKeyNavigation<ILiveTestingResponse>({
    items: props.responses,
    selectedItem: selectedTester,
    isItemValid: isAnswerHasVideo,
    getItemId: (item) => item.answerId,
    onSelect: (tester) => setSelectedTester(tester),
    onEscape: () => setIsVideoModalOpen(false),
    isEnabled: props.isSummaryReport
  });

  return (
    <div className="livetesting-bytester-list border-1 border-gray-250 rounded-lg overflow-x-auto overflow-y-hidden px-4">
      <table className="livetesting-bytester-list__table table-auto w-full border-collapse min-w-[400px]" aria-label="Example static collection table">
        <thead className="livetesting-bytester-list__header text-sm captionNoMargin">
          <tr>
            <th className="livetesting-bytester-list__header-date w-1/4 px-3 py-2 font-medium text-left min-w-[80px]">{t("Date")}</th>
            <th className="livetesting-bytester-list__header-status w-1/4 px-3 py-2 font-medium text-left">{t("Status")}</th>
            <th className="livetesting-bytester-list__header-time w-1/4 px-3 py-2 font-medium text-left">{t("Time")}</th>
            {isReportHasVideo && <th className="livetesting-bytester-list__header-replay w-1/4 py-2 px-3 shrink-0 text-left font-medium">{t("Replay")}</th>}
          </tr>
        </thead>
        <tbody>
          {props.responses.map((response, index) => (
            <TableIndividualReportRow
              key={`FigmaIndividualReport-${index}`}
              response={response}
              sharingToken={props.sharingToken}
              selecteTester={(tester) => {
                setSelectedTester(tester);
                setIsVideoModalOpen(true);
              }}
              isReportHasVideo={isReportHasVideo}
            />
          ))}
        </tbody>
      </table>

      <Modal width="95vw" height="90vh" isOpen={isVideoModalOpen} setIsOpen={setIsVideoModalOpen} background>
        <ScreenRecorderPlayer
          key={`screen-${selectedTester.answerId}-${props.blockId}`}
          recordingId={`${selectedTester.answerId}/${props.blockId}`}
          sharingToken={props.sharingToken}
          date={selectedTester.completedAt as unknown as string}
          closeModal={() => setIsVideoModalOpen(false)}
          screenRecord={selectedTester.recordResult?.screenRecord || null}
          cameraRecord={selectedTester.recordResult?.cameraRecord || null}
          microphoneRecord={selectedTester.recordResult?.audioRecord || null}
          isVideoScrollEnabled={props.isSummaryReport}
          duration={selectedTester.time} 
          blockType={BlockType.livetesting}
          showRespondentFaceRecord={true}
        />
      </Modal>
    </div>
  );
}   