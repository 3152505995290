import { BlockType } from '../../../../../../../models/Test/BlockType';
import { BlockPermissionSettings, PermissionType } from '../models/types';
import useIsMobile from '../../../hooks/useIsMobile';

interface IUseMediaPermissionsProps {
  permissions: BlockPermissionSettings;
  blockType: BlockType;
}

export default function useMediaPermissions(props: IUseMediaPermissionsProps) {
  const { permissions, blockType } = props;
  const isMobile = useIsMobile();

  const shouldDisableCamera = blockType === BlockType.livetesting && isMobile;
  
  return {
    hasCameraAccess: shouldDisableCamera ? false : permissions[PermissionType.CAMERA],
    hasAudioAccess: permissions[PermissionType.AUDIO],
    hasScreenVideoAccess: permissions[PermissionType.SCREEN]
  };
}