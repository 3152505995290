import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReportBlockHeader from "../ReportBlockHeader";
import { ILiveTestingResponse } from "../../../models/Response";
import { LivetestingBlock } from "../../../models/Test";
import { SummaryStats } from "../FigmaReport/SummaryStats";
import { TestBlockReportContext } from "../ReportContext";

import { getFormattedDate } from "../utils";
import ResponsesTable from "./Table/Table";
import useTaskCompletionStats from "../hooks/useTaskCompletionStats";

interface ILiveTesingReportProps {
  responses: ILiveTestingResponse[];
  isSummaryReport?: boolean;
  sharingToken?: string;
}

export default function LiveTesingReport(props: ILiveTesingReportProps) {
  const reportContext = useContext(TestBlockReportContext);
  const { t } = useTranslation();
  const block = reportContext?.block as LivetestingBlock;

  const liveTestingStats = useTaskCompletionStats(props.responses);

  const formattedResponses = useMemo(() =>
    props.responses.map((response) => ({
      ...response,
      time: Math.floor(response.time / 100) / 10,
      completedAt: getFormattedDate(new Date(response.completedAt)) as string,
    })), [props.responses, t]
  );

  return (
    <>
      {props.isSummaryReport && (
        <>
          <div className="figma-report__question flex flex-col gap-1">
            <span className="block captionNoMargin">{t("Task")}</span>
            <ReportBlockHeader questionText={block.text} className="mb-4" />
          </div>
          <SummaryStats stats={liveTestingStats.commonStatsInfo} className="my-6" />
        </>
      )}

      {formattedResponses && (
        <ResponsesTable
          responses={formattedResponses as unknown as ILiveTestingResponse[]}
          blockId={block.blockId}
          sharingToken={props.sharingToken}
          isSummaryReport={props.isSummaryReport!}
        />)
      }
    </>
  );
}

