import React from "react";
import { ScaleFunction } from './Models';
import { IReportPrototypeClick } from '../FigmaReport/FigmaReport';
import { Click } from "../FigmaReport/NativePrototypeScreenReport";

export interface IFigmaClicksProps {
  width: number;
  height: number;
  showClicksOrder: boolean;
  withScale: ScaleFunction;
  clicks: IReportPrototypeClick[];
}

function FigmaClicks({ width, height, clicks, withScale, showClicksOrder = false }: IFigmaClicksProps) {
  return (
    <div className="relative" style={{ width, height }}>
      {clicks?.map((click, index) => (
        <Click key={'click-' + index}
          click={click}
          showNumber={showClicksOrder}
          left={(click.clickData.left || 0)}
          top={(click.clickData.top || 0)}
        />
      ))}
    </div>
  );
}

export default FigmaClicks;
