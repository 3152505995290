import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";

import Loader from "../../../Loader";
import Header from "./components/Header";
import { Transcriptions, VideoFaceContainer } from "./components";

import { RecordTiming } from "../../../Common/RecordResult";

import { useDownloadRecording } from "../../hooks/useDownloadRecording";
import { useVideoContent } from "./hooks";
import { FigmaPlayerControllerButtons } from "./controls";
import { getRecordingPath } from "../../../Respondent/Pages/Test/components/BlockSettings/utils/getRecordingPath";
import { BlockType } from "../../../../models/Test/BlockType";

interface IFigmaVideoPlayerProps {
  blockId: string;
  answerId: string;
  date: string;
  closeModal: () => void;
  sharingToken?: string;
  cameraRecord: RecordTiming | null,
  microphoneRecord: RecordTiming | null,
  screenRecord?: RecordTiming | null,
  isVideoScrollEnabled: boolean,
  blockType: BlockType,
}

function FigmaVideoPlayer(props: IFigmaVideoPlayerProps) {
  const { events, error } = useDownloadRecording({ blockId: props.blockId, answerId: props.answerId });

  const [currentTime, setCurrentTime] = useState<number | undefined>();
  const [totalTime, setTotalTime] = useState<number | undefined>();
  const [playerState, setPlayerState] = useState<string | undefined>("playing");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<rrwebPlayer | undefined>(undefined);

  const hasTranscriptions = false;
  const recordingFolder = getRecordingPath(props.blockType);

  const { videoFaceUrl,
    videoFaceRef,
    withRespondentRecord,
    isPlayerLoading,
    setIsPlayerLoading,
    videoFaceError,
    handleVideoError,
  } = useVideoContent(recordingFolder,
    `${props.answerId}/${props.blockId}`,
    !!props.screenRecord,
    !!props.cameraRecord,
    !!props.microphoneRecord,
    props.sharingToken);

  useEffect(() => {

    return () => {
      if (playerRef.current) {
        // @ts-ignore: The developers didn't add this function to the interface :( 
        playerRef.current.$destroy();
      }
    }
  }, [])

  useEffect(() => {
    if (playerRef.current) return;
    if (!containerRef.current || !events) return;

    playerRef.current = new rrwebPlayer({
      target: containerRef.current,
      props: {
        autoPlay: false,
        events: events,
        speedOption: [1, 2, 4],
        showController: false,
        height: (containerRef.current.parentElement?.offsetHeight || 0) - 88,
        width: containerRef.current.parentElement?.offsetWidth
      },
    });

    playerRef.current.addEventListener("ui-update-current-time", (event: any) => {
      setCurrentTime(event.payload);
    });
    playerRef.current.addEventListener("ui-update-player-state", (event: any) => {
      setPlayerState(event.payload);
    });
    const meta = playerRef.current.getMetaData();
    setTotalTime(meta.totalTime);

    if (containerRef) {
      const replayer = containerRef.current.querySelector(".rr-player");
      replayer?.classList.add("replayer");
      replayer?.classList.remove("rr-player");
    }

    if (!withRespondentRecord) {
      setIsPlayerLoading(false);
    }

    const onResize = () => {
      if (playerRef.current && containerRef.current) {
        // @ts-ignore: The developers didn't add this function to the interface :( 
        playerRef.current.$set({
          height: (containerRef.current.parentElement?.offsetHeight || 0) - 88,
          width: containerRef.current.parentElement?.offsetWidth
        });
      }
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [events]);

  useEffect(() => {
    if (withRespondentRecord && !videoFaceRef.current) return;

    function updatePlayerLoading() {
      setIsVideoLoaded(true);
    }

    videoFaceRef.current?.addEventListener('loadedmetadata', updatePlayerLoading);

    return () => {
      videoFaceRef.current?.removeEventListener('loadedmetadata', updatePlayerLoading);
    };
  }, [videoFaceRef]);

  useEffect(() => {
    if ((isVideoLoaded || videoFaceError) && events) {
      setIsPlayerLoading(false);
    }
  }, [isVideoLoaded, events]);

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div>Unable to upload video. Please try again later or message us.</div>
      </div>
    )
  }

  return (
    <div className="figma-player w-full h-full bg-black">
      {isPlayerLoading && <Loader />}
      <div className={clsx("figma-player__container w-full h-full", isPlayerLoading ? "invisible absolute" : "visible relative")}>
        <Header date={props.date} closeModal={props.closeModal} isVideoScrollEnabled={props.isVideoScrollEnabled} />

        <div
          id="replayContainer"
          className="figma-player__replay-container video-replayer relative overflow-hidden bg-black"
          style={{
            height: "calc(100% - 125px)",
            opacity: isPlayerLoading ? 0 : 1
          }}
        >
          {hasTranscriptions && <div className='figma-player__transcriptions absolute top-5 right-5 z-20'> <Transcriptions /> </div>}

          {withRespondentRecord && <VideoFaceContainer
            videoFaceRef={videoFaceRef}
            videoFaceUrl={videoFaceUrl}
            isVisible={!!props.cameraRecord && !videoFaceError}
            onError={() => handleVideoError("face")}
          />}

          <div className="figma-player__html-player html__player absolute flex justify-center items-center w-full h-full" ref={containerRef} />
        </div>

        {playerRef.current && <FigmaPlayerControllerButtons
          replayer={playerRef.current}
          currentTime={currentTime}
          totalTime={totalTime}
          playerState={playerState}
          videoFaceRef={!videoFaceError ? videoFaceRef : null}
          hasAudio={!!props.microphoneRecord && !videoFaceError}
        />}
      </div>
    </div>
  );
};

export default FigmaVideoPlayer;
