import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { FigmaFlowReplayer } from '../replay';
import Loader from '../../../Loader';

import { CanvasSize } from '../../../../models/Response';
import { isMobile } from '../../utils';

import PlayerControls from './controls/PlayerControls';
import { useClickEvents, useVideoContent } from './hooks';
import { ErrorVideoMessage, Header, Transcriptions, VideoFaceContainer } from './components';
import Pointer from './components/Pointer';
import { RecordTiming } from '../../../Common/RecordResult';
import { getRecordingPath } from '../../../Respondent/Pages/Test/components/BlockSettings/utils/getRecordingPath';
import { BlockType } from '../../../../models/Test/BlockType';

export default function NativePrototypeVideoPlayer(props: {
  recordingId: string,
  sharingToken?: string,
  pixelRatio: number,
  size?: CanvasSize,
  userAgent: string | undefined,
  date: string,
  closeModal: () => void,
  cameraRecord?: RecordTiming | null,
  screenRecord?: RecordTiming | null,
  microphoneRecord?: RecordTiming | null,
  isVideoScrollEnabled: boolean,
  duration: number,
  blockType: BlockType,
}) {
  // ref for pointer
  const pointerRef = useRef<HTMLDivElement>(null);
  const prototypeVideoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const hasTranscriptions = false;
  const recordingFolder = getRecordingPath(props.blockType);

  const { videoUrl,
    videoFaceUrl,
    videoScreenError,
    videoFaceRef,
    isPlayerLoading,
    handleVideoError,
    withRespondentRecord,
    videoFaceError,
    handleReduceLoadedVideoCount
  } = useVideoContent(recordingFolder, props.recordingId, !!props.screenRecord, !!props.cameraRecord, !!props.microphoneRecord, props.sharingToken);

  // вычисляем масштаб на основе размера канваса, т.к. иногда соотношение размеров записи видео и координат может отличаться. Например видео=Х2, а координаты=Х3
  const actualPixelRatio = props.size ? props.size.canvasRealSize.width / props.size.canvasDisplaySize.width : props.pixelRatio;

  const { clicks, startTs, endTs, indentX, indentY } = useClickEvents(props.recordingId, props.sharingToken, props.size);

  const videoOffsets = useMemo(() => {
    const mainRecordTiming = !videoFaceError ? (props.cameraRecord || props.microphoneRecord) : null;
    const mainRecordStart = mainRecordTiming?.start || 0;
    const firstClickTs = Math.min(clicks?.[0]?.[0]?.ts || startTs, startTs);
    const mainRecordDuration = (mainRecordTiming?.stop || 0) - mainRecordStart;
    const prototypeDuration = endTs - startTs;
    // const clicksDuration = (_.last(_.last(clicks))?.ts || 0) - (_.first(_.first(clicks))?.ts || 0);
    // console.log('mainrecordDuration', mainRecordDuration, mainRecordTiming);
    // console.log('prototypeDuration', prototypeDuration, startTs, endTs);
    // console.log('clicksDuration', clicksDuration);
    // const minDuration = Math.min(prototypeDuration, clicksDuration, mainRecordDuration);
    // const maxDuration = Math.max(prototypeDuration, clicksDuration, mainRecordDuration);

    // console.log('firstClickTs', clicks?.[0]?.[0]?.ts, startTs, endTs, 'mainRecordStart', mainRecordStart, 'mainRecordDuration', mainRecordDuration);

    // const startOffset = Math.max((firstClickTs - mainRecordStart) / 1000, 0);
    const startOffset = Math.max(mainRecordDuration - prototypeDuration, 0) / 1000;
    const duration = Math.max((endTs - firstClickTs) / 1000, 0);
    // const duration = Math.max(prototypeDuration / 1000 + 1, 0);

    return {
      startOffset,
      duration
    }
  }, [clicks, props.cameraRecord, props.microphoneRecord, startTs, endTs]);

  useEffect(() => {
    if (clicks.length === 0) return;
    if (!pointerRef.current) return;
    if (!prototypeVideoRef.current) return;

    const replayer = new FigmaFlowReplayer(prototypeVideoRef.current, pointerRef.current, { pixelRatio: props.pixelRatio, isMobile: isMobile(props.userAgent) });
    replayer.replay(clicks, indentX, indentY, startTs, endTs, videoUrl || "", withRespondentRecord);

  }, [clicks, pointerRef.current, prototypeVideoRef.current]);

  useEffect(() => {
    if (!containerRef.current) return;
    if (!prototypeVideoRef.current) return;

    if (withRespondentRecord) {
      videoFaceRef.current?.addEventListener('loadedmetadata', handleReduceLoadedVideoCount);
    }

    function handleLoadedMetadata() {
      // console.log('DURATION2', prototypeVideoRef.current?.duration);
      const scaledVideoWidth = prototypeVideoRef.current!.videoWidth / actualPixelRatio;
      const scaledVideoHeight = prototypeVideoRef.current!.videoHeight / actualPixelRatio;

      const containerWidth = containerRef.current!.getBoundingClientRect().width;
      const containerHeight = containerRef.current!.getBoundingClientRect().height;
      const scaleWidth = containerWidth / scaledVideoWidth;
      const scaleHeight = containerHeight / scaledVideoHeight;
      const scale = (scaleHeight < 1 || scaleWidth < 1) ? Math.min(scaleWidth, scaleHeight) : 1;

      wrapperRef.current!.style.transform = `scale(${scale}) translate(-50%, -50%)`; //
      wrapperRef.current!.style.width = `${scaledVideoWidth}px`;
      wrapperRef.current!.style.height = `${scaledVideoHeight}px`;

      //sometimes video element is not scaled correctly on first load
      prototypeVideoRef.current!.style.width = `${scaledVideoWidth}px`;
      prototypeVideoRef.current!.style.height = `${scaledVideoHeight}px`;

      handleReduceLoadedVideoCount();
    }

    prototypeVideoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      prototypeVideoRef.current?.removeEventListener('loadedmetadata', handleLoadedMetadata);
      if (withRespondentRecord) {
        videoFaceRef.current?.removeEventListener('loadedmetadata', handleReduceLoadedVideoCount);
      }
    };
  }, [containerRef.current, prototypeVideoRef.current, videoFaceRef.current, withRespondentRecord]);


  return (
    <div className="native-prototype-player w-full h-full bg-black">
      {isPlayerLoading && <Loader />}
      <div className={clsx("native-prototype-player__container w-full h-full", isPlayerLoading ? "invisible absolute" : "visible relative")}>
        <Header date={props.date} closeModal={props.closeModal} isVideoScrollEnabled={props.isVideoScrollEnabled} />

        <div
          id="replayContainer"
          className="native-prototype-player__replay-container video-replayer relative overflow-hidden bg-black"
          style={{ height: "calc(100% - 125px)" }}
          ref={containerRef}
        >
          {hasTranscriptions && <div className='native-prototype-player__transcriptions absolute top-5 right-5 z-20'> <Transcriptions /> </div>}
          {videoScreenError && <ErrorVideoMessage type='notFound' />}

          {withRespondentRecord && <VideoFaceContainer
            videoFaceRef={videoFaceRef}
            videoFaceUrl={videoFaceUrl}
            isVisible={!!props.cameraRecord && !videoFaceError}
            onError={() => handleVideoError("face")}
          />}

          <div
            className="native-prototype-player__video-wrapper video-replayer__wrapper absolute"
            ref={wrapperRef}
            style={{
              top: "50%",
              left: "50%",
              transformOrigin: "left top",
            }}
          >
            <video className="video-replayer__video"
              ref={prototypeVideoRef}
              preload='auto'
              loop={false}
              onError={() => handleVideoError("screen")}
            >
              <source src={videoUrl} type="video/webm" />
            </video>

            <div className='native-prototype-player__pointer-container pointer-layer absolute w-full h-full top-0 left-0'>
              <Pointer pointerRef={pointerRef} isMobile={isMobile(props.userAgent)} />
            </div>
          </div>
        </div>

        {!isPlayerLoading && (!videoScreenError || !videoFaceError) &&
          <PlayerControls
            additionalVideoRef={withRespondentRecord && !videoFaceError && !videoScreenError ? prototypeVideoRef : undefined}
            mainVideoRef={withRespondentRecord && !videoFaceError ? videoFaceRef : prototypeVideoRef}
            hasAudio={!!props.microphoneRecord && !videoFaceError}
            videoOffsets={videoOffsets}
            duration={props.duration}
          />
        }
      </div>
    </div>
  );
}

