import { useEffect, useState, useRef } from "react";
import { PermissionState } from "../../BlockSettings/models/types";
import Permissions from "../../BlockSettings/models/Permissions";

export enum TaskStep {
  AUDIO_AND_CAMERA_PERMISSIONS_STEP = 0,
  AUDIO_PERMISSION_STEP = 1,
  CAMERA_PERMISSION_STEP = 2,
  SCREEN_PERMISSION_STEP = 3,
  ALL_SET_STEP_WITH_TASK_TEXT = 4,
}

export default function useStepNavigation(withAudio: boolean, withCamera: boolean, withVideo: boolean, isMobile: boolean, permissions: Permissions, isInitialPermissionsLoaded: boolean) {
  const activePermissions = [(withAudio || withCamera), !isMobile && withVideo].filter(Boolean);
  const [allSteps, setAllSteps] = useState(1);
  const [currentScreenStep, setCurrentScreenStep] = useState(1);

  const [step, setStep] = useState(TaskStep.ALL_SET_STEP_WITH_TASK_TEXT);
  const { camera, microphone } = permissions || {}; 

  const hasInitialized = useRef(false);

  function getNextStep(): number {
    const nextStepMap: { [key: number]: number } = {
      [TaskStep.AUDIO_AND_CAMERA_PERMISSIONS_STEP]: (withVideo && !isMobile)
        ? TaskStep.SCREEN_PERMISSION_STEP
        : TaskStep.ALL_SET_STEP_WITH_TASK_TEXT,
      [TaskStep.AUDIO_PERMISSION_STEP]: (withCamera && camera === PermissionState.PROMPT)
        ? TaskStep.CAMERA_PERMISSION_STEP
        : (withVideo && !isMobile)
          ? TaskStep.SCREEN_PERMISSION_STEP
          : TaskStep.ALL_SET_STEP_WITH_TASK_TEXT,
      [TaskStep.CAMERA_PERMISSION_STEP]: (withVideo && !isMobile)
        ? TaskStep.SCREEN_PERMISSION_STEP
        : TaskStep.ALL_SET_STEP_WITH_TASK_TEXT,
      [TaskStep.SCREEN_PERMISSION_STEP]: TaskStep.ALL_SET_STEP_WITH_TASK_TEXT,
    };

    let nextStep = nextStepMap[step];

    return nextStep;
  }

  function getAllSteps() {
    let grantedPermissions = 0;
    const isMicrophoneWithStatus = microphone !== PermissionState.PROMPT;
    const isCameraWithStatus = camera !== PermissionState.PROMPT;

    if ((withAudio && withCamera && isMicrophoneWithStatus && isCameraWithStatus) ||
        (withAudio && !withCamera && isMicrophoneWithStatus) ||
        (withCamera && !withAudio && isCameraWithStatus)) {
      grantedPermissions = 1;
    }

    setAllSteps(activePermissions.length - grantedPermissions);
  }

  useEffect(() => {
    if (permissions && isInitialPermissionsLoaded && !hasInitialized.current) {
      setStep(getFirstStep());
      hasInitialized.current = true;
      getAllSteps();
    }
  }, [isInitialPermissionsLoaded]);

  function getFirstStep() {
    let firstStep;

    if (withAudio && microphone === PermissionState.PROMPT && withCamera && camera === PermissionState.PROMPT) {
      firstStep = TaskStep.AUDIO_AND_CAMERA_PERMISSIONS_STEP;
    } else if (withAudio && microphone === PermissionState.PROMPT) {
      firstStep = TaskStep.AUDIO_PERMISSION_STEP;
    } else if (withCamera && camera === PermissionState.PROMPT) {
      firstStep = TaskStep.CAMERA_PERMISSION_STEP;
    } else if (withVideo && !isMobile) {
      firstStep = TaskStep.SCREEN_PERMISSION_STEP;
    } else {
      firstStep = TaskStep.ALL_SET_STEP_WITH_TASK_TEXT;
    }

    return firstStep;
  }

  function updateStep(newStep?: number): void {
    const stepToSet = newStep ?? getNextStep();
    setStep(stepToSet);
    setCurrentScreenStep(currentScreenStep + 1);
  }

  return { step, getNextStep, getFirstStep, updateStep, allSteps, currentScreenStep };
}