import React from "react";

import ScreenRecorderPlayer from "./ScreenRecorderPlayer";
import NativePrototypeVideoPlayer from "./NativePrototypeVideoPlayer"
import FigmaVideoPlayer from "./FigmaVideoPlayer";

import { IReportStateByTester, PrototypeType } from "../FigmaReport"
import { CanvasSize } from "../../../../models/Response";
import { BlockType } from "../../../../models/Test/BlockType";

interface VideoPlayerResolverProps {
  screenVideo: boolean;
  prototypeType: PrototypeType;
  size?: CanvasSize;
  tester: IReportStateByTester;
  closeModal: () => void;
  blockId: string;
  sharingToken?: string;
  pixelRatio: number;
  userAgent?: string;
  isVideoScrollEnabled: boolean;
}

// The ScreenRecorderPlayer is used for videos that have screen recordings or encounter errors with canvas recordings.
// The NativePrototypeVideoPlayer is used specifically for canvas videos.
// The FigmaVideoPlayer is used for plugin videos.

export default function VideoPlayerResolver(props: VideoPlayerResolverProps) {
  // withVideo - is a flag that indicates that the respondent has a video recording for old answers before Dec 2024
  const isCanvasExists = !!props.tester.recordResult?.canvasRecord || props.tester.withVideo

  if (!isCanvasExists) {
    return (
      <ScreenRecorderPlayer
        key={`screen-${props.tester.answerId}-${props.blockId}`}
        recordingId={`${props.tester.answerId}/${props.blockId}`}
        sharingToken={props.sharingToken}
        date={props.tester.date}
        closeModal={props.closeModal}
        screenRecord={props.tester.recordResult?.screenRecord || null}
        cameraRecord={props.tester.recordResult?.cameraRecord || null}
        microphoneRecord={props.tester.recordResult?.audioRecord || null}
        isVideoScrollEnabled={props.isVideoScrollEnabled}
        duration={parseFloat(props.tester.time)}
        blockType={BlockType.figma}
      />
    )
  } else if (props.prototypeType === PrototypeType.Native) {
    return (
      <NativePrototypeVideoPlayer
        key={`native-${props.tester.answerId}-${props.blockId}`}
        size={props.size}
        recordingId={`${props.tester.answerId}/${props.blockId}`}
        sharingToken={props.sharingToken}
        pixelRatio={props.pixelRatio}
        userAgent={props.userAgent}
        date={props.tester.date}
        closeModal={props.closeModal}
        cameraRecord={props.tester.recordResult?.cameraRecord || null}
        microphoneRecord={props.tester.recordResult?.audioRecord || null}
        isVideoScrollEnabled={props.isVideoScrollEnabled}
        duration={parseFloat(props.tester.time)}
        blockType={BlockType.figma}
      />
    );
  } else if (props.prototypeType === PrototypeType.Html) {
    return (
      <FigmaVideoPlayer
        key={`figma-${props.tester.answerId}-${props.blockId}`}
        blockId={props.blockId}
        answerId={props.tester.answerId}
        date={props.tester.date}
        closeModal={props.closeModal}
        sharingToken={props.sharingToken}
        cameraRecord={props.tester.recordResult?.cameraRecord || null}
        microphoneRecord={props.tester.recordResult?.audioRecord || null}
        isVideoScrollEnabled={props.isVideoScrollEnabled}
        blockType={BlockType.figma}
      />
    );
  }

  return <></>
}
