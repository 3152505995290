import React, { useMemo } from "react";

import { IconMic, IconCamera, IconScreen, IconScreenOff, IconMicrophoneOff, IconCameraOff } from "../../../../../../../icons";
import { usePermissionsContext } from "../context/PermissionsContext";
import { locale, interfaceText } from "../../../../../../../helpers";
import useIsMobile from "../../../hooks/useIsMobile";
import { PermissionType, PermissionState } from "../models/types";

export interface SettingsItem {
  id: PermissionType;
  name: string;
  icon: React.JSX.Element;
  iconDisabled: React.JSX.Element;
  permissionState: PermissionState;
  canBeGrantedAgain: boolean; // if browser allows to grant permission again and show alert
}

interface UseBlockSettingsLogicProps {
  withCamera: boolean;
  withAudio: boolean;
  withVideo: boolean;
}

export default function useBlockRecordingLogic(props: UseBlockSettingsLogicProps) {
  const isMobile = useIsMobile();
  const { permissions, requestPermission, blockPermissionSettings, setBlockPermissionSettings } = usePermissionsContext();

  const UserPermissions: { [key: string]: SettingsItem } = {
    [PermissionType.SCREEN]: {
      id: PermissionType.SCREEN,
      name: interfaceText.permissionsSettings[locale()].screen,
      icon: <IconScreen width={24} height={24} className="fill-current text-gray-800" />,
      iconDisabled: <IconScreenOff className="text-gray-400" width={24} height={24} />,
      permissionState: PermissionState.PROMPT,
      canBeGrantedAgain: true,
    },
    [PermissionType.CAMERA]: {
      id: PermissionType.CAMERA,
      name: interfaceText.permissionsSettings[locale()].camera,
      icon: <IconCamera width={24} height={24} className="fill-current text-gray-800" />,
      iconDisabled: <IconCameraOff className="text-gray-400" width={24} height={24} />,
      permissionState: PermissionState.PROMPT,
      canBeGrantedAgain: false,
    },
    [PermissionType.AUDIO]: {
      id: PermissionType.AUDIO,
      name: interfaceText.permissionsSettings[locale()].microphone,
      icon: <IconMic width={24} height={24} className="fill-current text-gray-800" />,
      iconDisabled: <IconMicrophoneOff className="text-gray-400" width={24} height={24} />,
      permissionState: PermissionState.PROMPT,
      canBeGrantedAgain: false,
    },
  };

  const allRequiredPermissionsGranted = useMemo(() => [
    props.withCamera && [PermissionState.GRANTED].includes(permissions.camera),
    props.withAudio && [PermissionState.GRANTED].includes(permissions.microphone),
    isMobile || (props.withVideo && [PermissionState.GRANTED].includes(permissions.screen))
  ].every(Boolean), [props.withCamera, props.withAudio, props.withVideo, permissions, isMobile]);

  const someRequiredPermissionsDeniedOrPrompt = useMemo(() => [
    props.withCamera && [PermissionState.DENIED, PermissionState.PROMPT].includes(permissions.camera),
    props.withAudio && [PermissionState.DENIED, PermissionState.PROMPT].includes(permissions.microphone),
    props.withVideo && !isMobile && [PermissionState.DENIED, PermissionState.PROMPT].includes(permissions.screen)
  ].some(Boolean), [props.withCamera, props.withAudio, props.withVideo, permissions, isMobile]);

  function getPermissions() {
    let userPermissions = [];

    if (props.withAudio) {
      const userPermissionAudio = { ...UserPermissions[PermissionType.AUDIO], permissionState: permissions.microphone };
      userPermissions.push(userPermissionAudio);
    }

    if (props.withCamera) {
      const userPermission = { ...UserPermissions[PermissionType.CAMERA], permissionState: permissions.camera };
      userPermissions.push(userPermission);
    }

    if (props.withVideo && !isMobile) {
      const userPermission = { ...UserPermissions[PermissionType.SCREEN], permissionState: permissions.screen };
      userPermissions.push(userPermission);
    }

    return userPermissions;
  }

  function togglePermissionState(permissionId: PermissionType) {
    if (permissionId === PermissionType.SCREEN && blockPermissionSettings[permissionId] === false) {
      requestPermission(PermissionType.SCREEN);
    }

    setBlockPermissionSettings(prev => {
      const updated = prev.clone();
      updated[permissionId] = !prev[permissionId];
      return updated;
    });
  };

  const userPermissions = getPermissions();

  const blockedPermissionsMessage =
  someRequiredPermissionsDeniedOrPrompt ? (
    <p className="text-gray-600 text-left w-full pb-2 text-sm">
      {interfaceText.permissionsSettings[locale()].blockedPermissionsInfo}
    </p>
  ) : null;

  return {
    userPermissions,
    permissions,
    blockPermissionSettings,
    togglePermissionState,
    requestPermission,
    allRequiredPermissionsGranted,
    someRequiredPermissionsDeniedOrPrompt,
    blockedPermissionsMessage
  };
}
