import { motion } from "framer-motion";
import parse from "html-react-parser";
import React from "react";

import { interfaceText, locale } from "../../../../../../helpers";
import Button from "../../../../../Button";
import { PermissionView } from "../FigmaBlock/PermissionView";
import { PermissionType } from "./models/types";

interface IPermissionScreenProps {
  isMobile: boolean;
  updateStep: () => void;
  handleRequestPermissions: (permissionType: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA]) => void;
  permissionType: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA];
  title: string;
  description: string;
  withAudio?: boolean;
  withCamera?: boolean;
  step: number;
  allSteps: number;
}

export default function PermissionScreen(props: IPermissionScreenProps) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.25, delay: 0.25 }}
      className="flex flex-col gap-4 h-full justify-between"
    >
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-bold">{interfaceText.figmaTestingMission[locale()].permissionSetup}</h3>
        <p className="text-gray-800">{interfaceText.figmaTestingMission[locale()].permissionInstruction}</p>
        <p className="text-sm text-gray-700">
          {interfaceText.figmaTestingMission[locale()].stepText.replace("{step}", props.step.toString()).replace("{allSteps}", props.allSteps.toString())}
        </p>
        <PermissionView type={props.permissionType} />
        <div className="flex flex-col gap-3">
          <p className="text-base+ font-medium">{props.title}</p>
          <p className="text-sm text-gray-800">{parse(props.description)}</p>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 w-full justify-between">
          <Button
            className={"figma-task__button-continue"}
            name={interfaceText.figmaTestingMission[locale()].skip}
            handler={() => props.updateStep()}
            large
            type="secondary"
            fullWidth={props.isMobile}
          />
          <Button
            className={"figma-task__button-continue"}
            name={interfaceText.figmaTestingMission[locale()].continue}
            handler={() => props.handleRequestPermissions(props.permissionType)}
            large
            type="primary"
            fullWidth={props.isMobile}
          />
        </div>
      </div>
    </motion.div>
  );
}