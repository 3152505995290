import React from "react";
import clsx from "clsx";

import { IconCancel, IconTick } from "../../../../../../icons";
import { locale, interfaceText } from "../../../../../../helpers";
import Switcher from "../../../../../Switcher";
import { PermissionState } from "./models/types";
import Popover from "../../../../../Popover2";
import useBlockRecordingLogic, { SettingsItem } from "./hooks/useBlockRecordingLogic";

interface BlockRecordingSettingsProps {
  withCamera: boolean;
  withAudio: boolean;
  withVideo: boolean;
  isPopoverView?: boolean;
}

export default function BlockRecordingSettings(props: BlockRecordingSettingsProps) {
  const {
    userPermissions,
    blockPermissionSettings,
    togglePermissionState,
    requestPermission,
    allRequiredPermissionsGranted,
    blockedPermissionsMessage
  } = useBlockRecordingLogic(props);

  function renderPermissionItem(permission: SettingsItem, index: number) {
    const showSettingsSwitcher = permission.permissionState === PermissionState.GRANTED;
    const showEnableButton =
      permission.permissionState === PermissionState.PROMPT ||
      (permission.canBeGrantedAgain && permission.permissionState === PermissionState.DENIED);

    return (
      <React.Fragment key={permission.id}>
        <div className={clsx("block-settings__permission-item flex items-center justify-between", props.isPopoverView ? "" : "px-3")}>
          <div className="block-settings__permission flex items-center gap-2">
            {showSettingsSwitcher && (
              <Switcher handler={() => togglePermissionState(permission.id)} isOn={blockPermissionSettings[permission.id]} />
            )}
            <div className="flex items-center gap-2">
              {permission.permissionState === PermissionState.GRANTED ? permission.icon : permission.iconDisabled}
              <p className={`block-settings__permission-name font-medium ${permission.permissionState === PermissionState.GRANTED ? "text-gray-800" : "text-gray-600"}`}>
                {permission.name}
              </p>
            </div>
          </div>

          {showEnableButton ? (
            <button className="text-blue-500 font-medium" onClick={() => requestPermission(permission.id)}>
              {interfaceText.permissionsSettings[locale()].enable}
            </button>
          ) : permission.permissionState === PermissionState.DENIED ? (
            <div className="flex items-center gap-1">
              <IconCancel fill="#F8511D" width={20} height={20} />
              <p className="text-orange-600">{interfaceText.permissionsSettings[locale()].blocked}</p>
            </div>
          ) : (
            <IconTick className="text-green-500 fill-current" width={20} height={20} />
          )}
        </div>
        {index < userPermissions.length - 1 && (
          <div className="block-settings__divider border-b-1 border-gray-200"></div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="block-settings flex flex-col gap-4 w-full">
      {allRequiredPermissionsGranted && <p className="text-gray-700 text-left w-full">{interfaceText.permissionsSettings[locale()].allowPermissionsInfo}</p>}
      <div className={clsx("block-settings__permissions flex flex-col gap-2 rounded-lg", props.isPopoverView ? "" : "border-1 border-gray-200 shadow-xxs p-3")}>
        {userPermissions.map(renderPermissionItem)}
      </div>
      {!props.isPopoverView && blockedPermissionsMessage}
    </div>
  );
}

export function BlockRecordingSettingsMinimalView(props: BlockRecordingSettingsProps & { isDisabled?: boolean }) {
  const {
    userPermissions,
    blockPermissionSettings,
    blockedPermissionsMessage
  } = useBlockRecordingLogic(props);

  function renderPermissionIcon(permission: SettingsItem) {
    const isInactive = permission.permissionState === PermissionState.PROMPT;
    return isInactive ? permission.iconDisabled : permission.icon;
  }

  function renderPermissionState(permission: SettingsItem) {
    return permission.permissionState === PermissionState.GRANTED && blockPermissionSettings[permission.id] === false ? (
      <div className="text-orange-600 w-2 h-2 rounded-full bg-orange-600" />
    ) : permission.permissionState === PermissionState.GRANTED ? (
      <div className="w-2 h-2 rounded-full bg-green-500" />
    ) : null;
  }

  return (
    <div
      className="block-settings__popover-wrapper flex items-center justify-center"
      onClick={e => {
        if (props.isDisabled) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      style={{ pointerEvents: props.isDisabled ? 'none' : 'auto', opacity: props.isDisabled ? 0.5 : 1 }}
    >
      <Popover
        position="top-start"
        content={() => (
          <div className="flex flex-col gap-3 w-[320px] p-2">
            <h3 className="text-gray-800 font-medium text-base+">{interfaceText.permissionsSettings[locale()].recordingSettings}</h3>
            {blockedPermissionsMessage}
            <BlockRecordingSettings {...props} isPopoverView />
          </div>
        )}>

        <div className="block-settings flex hover:cursor-pointer rounded-full transition-all duration-75 ease-in-out gap-4 p-1 hover:bg-gray-200">
          {userPermissions.map((permission) => (
            <div className="block-settings__permission flex items-center gap-1" key={permission.id}>
              {renderPermissionIcon(permission)}
              {renderPermissionState(permission)}
            </div>
          ))}
        </div>
      </ Popover>
    </div>
  );
}