import { IFileStorage } from './IFileStorage';
import { XMLParser } from "fast-xml-parser";
import axios from 'axios';

export class AWSFileStorage implements IFileStorage {

	constructor() { }

	public async uploadBytes(file: Blob, fileName: string, metadata?: any): Promise<any> {
		const uploadUrl = await this.getUploadURL(fileName);
		await this.uploadFile(uploadUrl, file);
		return uploadUrl.split('?')[0];
	}

	public async uploadString(file: string, fileName: string, metadata?: any): Promise<any> {
		const uploadUrl = await this.getUploadURL(fileName);
		await this.uploadFile(uploadUrl, file);
	}

	public async getUploadURL(fileName: string): Promise<string> {
		try {
			const response = await axios.get(`/api/v1/storage/upload-url/${fileName}`);
			// const response = await axios.get(`http://localhost:3000/api/v1/storage/upload-url/${fileName}`, { responseType: 'json' });
			// console.log('response', response.data);
			return response.data.url;
		} catch (error) {
			console.error('Error fetching upload URL:', error);
			throw new Error('Could not fetch upload URL');
		}
	}

	private async uploadFile(uploadUrl: string, data: Blob | string): Promise<any> {
		try {
			const headers: Record<string, string> = {};

			if (typeof data === 'string') {
				data = new Blob([data]);
			}

			const response = await axios.put(uploadUrl, data, {
				headers,
				transformRequest: [(data) => data],
			});

			// const parser = new XMLParser();
			// return parser.parse(response.data, true);
		} catch (error) {
			if (error instanceof Error && 'response' in error) {
				const parser = new XMLParser();
				throw parser.parse((error as any).response.data, true);
			} else {
				throw error;
			}
		}
	}
}
