import { useRef } from "react";
import { listenerHandler } from "@rrweb/types";
import * as rrweb from "rrweb";

import useFileStorage from "../../../../../Common/storage/useFileStorage";

export interface IPrototypeRecordingOptions {
  blockId: string;
  answerId: string; 
  setIsUploading: (value: boolean) => void;
}

export default function useHtmlPrototypeRecording({ blockId, answerId, setIsUploading }: IPrototypeRecordingOptions) {
  // const [events, setEvents] = useState<any[]>([]);
  const fileStorage = useFileStorage();

  const stopRecording = useRef<listenerHandler | undefined>();
  const recordedEvents = useRef<any[]>([]);


  function start() {
    stopRecording.current = rrweb.record({
      emit(event) {
        recordedEvents.current.push(event);
      },
    });
  }

  async function finish() {
    if (stopRecording.current) {
      stopRecording.current();
    }
    // clearTimeout(recordingTimeout.current);

    // TODO: The best practice is to make such a request 
    // at the time the answer is saved
    // sendRecordingData(events);

    if (recordedEvents.current?.length) {
      try {
        setIsUploading(true);
        const task = await fileStorage.uploadString(JSON.stringify(recordedEvents.current), `recordings/${answerId}-${blockId}.json`, 'raw');

        setIsUploading(false);
      } catch (error) {
        console.error('Error while saving video recording', `${answerId}-${blockId}`, error)
      }
    }
  }

  function recordEvent(eventName: string, payload: Record<string, string>) {
    rrweb.record.addCustomEvent(eventName, payload);
  }

  return { start, finish, recordEvent };
};
