import React from "react";

import { IconCirclePlay } from "../../icons";
import { IBlockRecordResponse } from "../../models/Response";
import { loaderColorMap } from "../Button";
import Loader from "../Loader";

export enum VideoStatus {
  DONE = "done",
  PROCESSING = "processing",
  UNAVAILABLE = "unavailable",
} 

export class ResponseVideoData {
  public readonly videoStatus: VideoStatus;
  public readonly statusIcon: React.ReactNode;

  constructor(block: IBlockRecordResponse & { withVideo?: boolean }) {
    this.videoStatus = this.determineVideoStatus(block);
    this.statusIcon = this.getVideoStatusIcon(this.videoStatus);
  }

  private determineVideoStatus(tester: IBlockRecordResponse & { withVideo?: boolean }): VideoStatus {
    const isScreenVideoAvailable =
      (!!tester.recordResult?.canvasRecord && !tester.recordResult?.canvasErrorReason) ||
      (!!tester.recordResult?.screenRecord && !tester.recordResult?.screenErrorReason);
  
    const isRespondentVideoAvailable =
      (!!tester.recordResult?.audioRecord && !tester.recordResult?.audioErrorReason) ||
      (!!tester.recordResult?.cameraRecord && !tester.recordResult?.cameraErrorReason);
  
    return tester.recordResult
      ? (isScreenVideoAvailable || isRespondentVideoAvailable ? VideoStatus.DONE : VideoStatus.UNAVAILABLE)
      : ('withVideo' in tester && tester.withVideo ? VideoStatus.DONE : VideoStatus.UNAVAILABLE);
  }

  private getVideoStatusIcon(videoStatus: VideoStatus): React.ReactNode {
    switch (videoStatus) {
      case VideoStatus.DONE:
        return (<IconCirclePlay width={24} height={24} className="fill-current text-gray-800 hover:opacity-75 transition-opacity duration-75 ease-in-out" />)
      case VideoStatus.PROCESSING:
        return (<Loader color={loaderColorMap["primary"]} className="mr-2" />)
      case VideoStatus.UNAVAILABLE:
        return null;
      default:
        return null;
    }
  }
}