export enum ConditionType {
	contains = "contains",
	doesNotContain = "doesNotContain",
	is = "is",
	isEmpty = "isEmpty",
	isGreaterThan = "isGreaterThan",
	isLessThan = "isLessThan",
	gaveUp = "gaveUp",
	completed = "completed",
	goalScreenIs = "goalScreenIs"
}